import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-horizontal-scrolling-menu/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Approutes from "./Approutes";
import TagManager from "react-gtm-module";
import Aos from "aos";
import { useEffect } from "react";

const tagManagerArgs = {
  gtmId: 'GTM-PMKVMHVZ'
}

TagManager.initialize(tagManagerArgs)

function App() {

  return (
    <div className="App">
      <Approutes />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        theme="colored"
        pauseOnHover={false}
      />
    </div>
  );
}

export default App;
