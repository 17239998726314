import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsCart3 } from "react-icons/bs";
import {
    Badge,
    Button,
    Container,
    Dropdown,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Image,
    InputGroup,
    Modal,
    Nav,
    NavLink,
    Navbar,
    NavbarBrand,
    NavbarCollapse,
    NavbarToggle,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    OffcanvasTitle,
} from "react-bootstrap";
import "./Navbar.css";
import { FaMinus, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import UpdateProfile from "../UpdateProfile";
import LoginWithPassword from "../LoginWithPassword";
import ChangePassword from "../ChangePassword";
import { FaUserCircle } from "react-icons/fa";
import { FaLocationDot, FaCartShopping } from "react-icons/fa6";
import { MdOutlinePassword } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import PrimaryButton from "../PrimaryButton";
import LoginWithOtp from "../LoginWithOtp";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../state/userSlice";
import {
    clearCart,
    setCartCount,
    setCartId,
    setCartItems,
    setIsCartOpen,
} from "../../state/cartSlice";
import {
    clearAddresses,
    setAddresses,
    setIsSelectedPage,
    setShippingAddressId,
} from "../../state/addressSlice";

const NavbarComponent = () => {
    const userDetails = useSelector((state) => state.user);
    const {
        cartItems,
        cartCount,
        isCartOpen,
        offer_price,
        totalCartQty,
        cartId,
        cartMrp,
    } = useSelector((state) => state.cart);
    let cartIdFromLocalStorage = localStorage.getItem("cart_id");
    let accessToken = localStorage.getItem("access_token");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cartProducts, setCartProducts] = useState([]);
    const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
    const pathName = window.location.pathname;
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [openSignUpModal, setOpenSignUpModal] = useState(false);
    const [signUpData, setSignUpData] = useState({
        name: "",
        mobile: null,
        email: "",
        password: "",
        otp: null,
    });
    const [openUpdateProfileModal, setOpenUpdateProfileModal] = useState(false);
    const [openLoginWithPasswordModal, setopenLoginWithPasswordModal] =
        useState(false);
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const [isSignnedUp, setIsSignnedUp] = useState(false);
    const [isOtpSending, setIsOtpSending] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleDropdownClick = (action) => {
        action();
        setShowDropdown(false);
    };

    const handleShow = () => {
        dispatch(setIsCartOpen(true));
        getCartProducts();
    };

    const handleClose = () => dispatch(setIsCartOpen(false));

    const handleSignUpInputChange = (e) => {
        const { name, value } = e.target;
        setSignUpData((prev) => ({ ...prev, [name]: value }));
    };

    const sendSignUpOtp = async () => {
        if (!signUpData?.mobile?.trim()) {
            toast.error("Mobile number field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^\d+$/.test(signUpData?.mobile)) {
            toast.error("Mobile number should contain only digits.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (signUpData?.mobile?.length !== 10) {
            toast.error("Mobile number should be 10 digits long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[6-9]/.test(signUpData?.mobile)) {
            toast.error("Mobile must start with 6,7,8 or 9.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }
        setIsOtpSending(true);
        try {
            const response = await axios.post("v2/auth/otp", {
                mobile: signUpData.mobile,
            });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();

                const doc = parser.parseFromString(form_error, "text/html");

                const errorMessage = doc.body.textContent;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
        setTimeout(() => {
            setIsOtpSending(false);
        }, 30000);
        startCountdown();
    };

    const startCountdown = () => {
        setCountdown(30);
        const timer = setInterval(() => {
            setCountdown((prevCount) => prevCount - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);
        }, 30000);
    };

    const createNewUser = async (e) => {
        e.preventDefault();
        if (!signUpData?.name?.trim()) {
            toast.error("Name field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[a-zA-Z][a-zA-Z\s\-']*$/.test(signUpData?.name)) {
            toast.error(
                "Name field should start with a letter and can only contain letters (both uppercase and lowercase), spaces, hyphens, or apostrophes.",
                {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                }
            );
            return;
        }

        if (signUpData?.name?.length < 3) {
            toast.error("Name should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (/\-{2,}/.test(signUpData?.name)) {
            toast.error("Name field cannot contain consecutive hyphens.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!signUpData?.mobile?.trim()) {
            toast.error("Mobile number field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^\d+$/.test(signUpData?.mobile)) {
            toast.error("Mobile number should contain only digits.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (signUpData?.mobile?.length !== 10) {
            toast.error("Mobile number should be 10 digits long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[6-9]/.test(signUpData?.mobile)) {
            toast.error("Mobile must start with 6,7,8 or 9.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }
        if (signUpData?.email) {
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpData?.email)) {
                toast.error("Invalid email address.", {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                return;
            }
        }
        setIsSignnedUp(true);
        try {
            const response = await axios.post("v2/auth/signup", signUpData);
            if (response?.data?.status === true) {
                const accessToken = response?.data?.data?.data?.access_token;
                const { full_name, email, mobile } = response?.data?.data?.data;
                localStorage.setItem("access_token", accessToken);
                dispatch(
                    setUserDetails({ name: full_name, email: email, phoneNumber: mobile })
                );
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                setSignUpData({
                    name: "",
                    mobile: null,
                    email: "",
                    password: "",
                    otp: null,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();
                const doc = parser.parseFromString(form_error, "text/html");

                const errorList = Array.from(doc.querySelectorAll("li")).map(
                    (li) => li.textContent
                );

                const errorMessage = (
                    <ul>
                        {errorList.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                );

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response.status === 401) {
                let errorMessage = error.response.data.data.message;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsSignnedUp(false);
        }
    };

    const getCartProducts = async () => {
        try {
            const response = await axios.get(
                `cart/list?cart=${cartIdFromLocalStorage}`
            );
            if (response?.data?.status === true) {
                const productsData = response?.data?.data?.cart;
                const cartItems = productsData?.item_list;
                setCartProducts(productsData);
                dispatch(setCartId(productsData?.id));
                dispatch(setCartItems(cartItems));
                dispatch(setCartCount(cartItems?.length));

                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        currency: "INR",
                        value: cartItems?.grand_total,
                        items: cartItems?.map((item, index) => ({
                            item_id: item?.id,
                            item_name: item?.product_name,
                            affiliation: "UP LIFE",
                            discount: cartItems?.discount_amount,
                            index: index,
                            item_brand: "UP LIFE",
                            item_variant: item?.variant_title,
                            price: item?.offer_price,
                            quantity: item?.quantity,
                        })),
                    }
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const deleteCartItem = async (id) => {
        setIsDeleteSuccess(true);
        try {
            const response = await axios.delete("cart/remove", { data: { id } });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });

                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: "remove_from_cart",
                    ecommerce: {
                        currency: "INR",
                        value: cartItems?.grand_total,
                        items: cartItems?.map((item, index) => ({
                            item_id: item?.id,
                            item_name: item?.product_name,
                            affiliation: "UP LIFE",
                            discount: cartItems?.discount_amount,
                            index: index,
                            item_brand: "UP LIFE",
                            item_variant: item?.variant_title,
                            price: item?.offer_price,
                            quantity: item?.quantity,
                        })),
                    }
                });

                getCartProducts();
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleteSuccess(false);
        }
    };

    const updateCartItem = async (cartId, productId, qty) => {
        try {
            const data = {
                cart_id: cartId,
                product: productId,
                quantity: qty,
            };

            const response = await axios.put("cart/update", data);
            if (response?.data?.status === true) {
                getCartProducts();
            }
        } catch (error) {
            if (error?.response && error?.response?.data?.status === false) {
                let errorMessage = error?.response?.data?.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const handleLogOut = () => {
        navigate("/");
        toast.success("You logged out successfully", {
            position: "bottom-center",
            autoClose: 2000,
            theme: "colored",
        });
        localStorage.clear();
        dispatch(clearAddresses())
        dispatch(clearCart());
    };

    const handleOpenLoginWithPassword = () => {
        setOpenLoginModal(false);
        setopenLoginWithPasswordModal(true);
    };

    const getUserData = async () => {
        try {
            let accessToken = localStorage.getItem("access_token");
            const headers = {
                Authorization: accessToken,
            };
            const response = await axios.get("v2/account/profile", { headers });
            if (response?.data?.status === true) {
                const userData = response?.data?.data?.profile;
                dispatch(
                    setUserDetails({
                        name: userData?.full_name,
                        email: userData?.email,
                        phoneNumber: userData?.mobile,
                    })
                );
                getAddressList();
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const getAddressList = async () => {
        try {
            let accessToken = localStorage.getItem("access_token");
            const headers = {
                Authorization: accessToken,
            };
            const response = await axios.get("v2/address/get", { headers });
            if (response?.data?.status === true) {
                const address = response?.data?.data?.list;
                const firstAddressId = address[0]?.id;
                dispatch(setShippingAddressId(firstAddressId));
                dispatch(setAddresses([address]));
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const handleNavigation = () => {
        navigate("/address")
        dispatch(setIsCartOpen(false));
    };

    const goToAddressPage = () => {
        navigate("/my-address");
    };

    const navigateToHome = () => {
        navigate("/sleepzzz-pack-of-1");
        dispatch(setIsCartOpen(false));
    };

    useEffect(() => {
        getUserData();
        getAddressList();
        getCartProducts();
    }, []);

    useEffect(() => {
        if (countdown === 0) {
            clearInterval(startCountdown);
        }
    }, [countdown]);


    return (
        <>
            <header>
                <Navbar expand="lg" className="bg-body-tertiary uplife-navbar">
                    <Container>
                        <div className="d-lg-block d-none" style={{ width: '43%' }}>
                        </div>
                        <Navbar.Brand
                            className="cursor-pointer"
                            onClick={() => navigate("/")}
                        >
                            <Image
                                src="/assets/uplife.png"
                                alt="brand-logo"
                                className="brand-img"
                            />
                        </Navbar.Brand>
                        <Nav className="ms-auto">
                            {accessToken ? (
                                <>
                                    <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
                                        <Dropdown.Toggle
                                            id="dropdown-basic"
                                            className="text-white"
                                            style={{ position: 'relative', backgroundColor: "transparent", border: "none" }}
                                        >
                                            <img src="/assets/user-alt-icon.png" alt="profile-icon" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="dropdown-menu" style={{ position: 'absolute' }}>
                                            <a class="dropdown-item text-center">
                                                <img src="/assets/user-icon.png" class="user-icon" alt="" />
                                                <div class="d-inline-block" style={{ textAlign: 'left' }}>
                                                    <span class="d-block"> {userDetails?.name}</span>
                                                    <small class="d-block">{userDetails?.email}</small>
                                                    <small class="d-block">+91 {userDetails?.phoneNumber}</small>
                                                </div>
                                            </a>
                                            <a class="dropdown-item" onClick={() => handleDropdownClick(() => setOpenUpdateProfileModal(true))}>
                                                <img src="/assets/user-icon.png" class="menu-icon menu-user-icon" alt="" />
                                                <div class="d-inline-block">
                                                    <span>Profile</span>
                                                    <small class="d-block">Change or add profile details</small>
                                                </div>
                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                            </a>
                                            <a class="dropdown-item" onClick={() => handleDropdownClick(() => goToAddressPage())}>
                                                <img src="/assets/location-icon.png" class="menu-icon" alt="" />
                                                <div class="d-inline-block">
                                                    <span>Addresses</span>
                                                    <small class="d-block">Change, add, delete addresses</small>
                                                </div>
                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                            </a>
                                            <a class="dropdown-item" onClick={() => handleDropdownClick(() => setOpenChangePasswordModal(true))}>
                                                <MdOutlinePassword size={20} class="menu-icon" />
                                                <div class="d-inline-block">
                                                    <span>Change Password</span>
                                                    <small class="d-block">Update Password</small>
                                                </div>
                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                            </a>
                                            <a class="dropdown-item" onClick={() => handleDropdownClick(() => navigate("/orders"))}>
                                                <img src="/assets/cart-alt-icon.png" class="menu-icon" alt="" />
                                                <div class="d-inline-block">
                                                    <span>Order History</span>
                                                    <small class="d-block">Check order history and status</small>
                                                </div>
                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                            </a>
                                            <a class="dropdown-item" onClick={() => handleDropdownClick(() => handleLogOut())}>
                                                <img src="/assets/exit-icon.png" class="menu-icon" alt="" />
                                                <div class="d-inline-block">
                                                    <span>Logout</span>
                                                    <small class="d-block">Log out of this device</small>
                                                </div>
                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                            </a>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            ) : (
                                <button
                                    className="login-btn mx-lg-2 mx-md-2 mx-2"
                                    onClick={() => setOpenLoginModal(true)}
                                >
                                    Log In
                                </button>
                            )}
                        </Nav>
                        <div
                            onClick={handleShow}
                            className="pointer-cursor "
                            style={{ position: "relative", display: "inline-block" }}
                        >
                            <BsCart3 className="cart-icon" size={25} style={{ color: "white", marginRight: "5px" }} />

                            <Badge
                                bg="danger"
                                style={{ position: "absolute", top: -8, right: -15, marginRight: "8px" }}
                            >
                                {cartCount}
                            </Badge>
                        </div>
                    </Container>
                </Navbar>

                <Offcanvas
                    show={isCartOpen}
                    placement="end"
                    onHide={handleClose}
                    style={{ width: "530px" }}
                >
                    <OffcanvasHeader closeButton>
                        <OffcanvasTitle>Your Cart ( {totalCartQty} ) </OffcanvasTitle>
                    </OffcanvasHeader>
                    <hr />
                    <OffcanvasBody>
                        {cartItems && cartItems?.length === 0 ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "100px",
                                }}
                            >
                                <img
                                    style={{ width: "350px" }}
                                    src="/assets/empty_cart.png"
                                    alt="empty-cart-image"
                                />
                            </div>
                        ) : isDeleteSuccess ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "100px 0",
                                }}
                            >
                                <RotatingLines
                                    visible={true}
                                    height="50"
                                    width="50"
                                    color="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    ariaLabel="rotating-lines-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                        ) : (
                            <div style={{ height: "60%", overflow: "auto" }}>
                                {cartItems?.map((product) => (
                                    <div key={product?.id}>
                                        <div className="d-flex justify-content-around aligh-items-center gap-2">
                                            <div style={{ width: "32%" }}>
                                                <img
                                                    src={product?.product_image}
                                                    alt="bottle-image"
                                                    width="100%"
                                                />
                                            </div>
                                            <div style={{ width: "45%" }} className="mt-3">
                                                <h6 className="fw-bold">{product?.product_name}</h6>
                                                <small>({product?.content_qty} Gummies)</small>
                                                <br />
                                                <small className="text-warning">
                                                    <i>{product?.variant_title}</i>
                                                </small>
                                                <br />
                                                <small className="fw-semibold">
                                                    Qty: {product?.quantity}{" "}
                                                </small>
                                                <p className="fw-semibold cart-mrp-price" style={{ position: 'relative' }}>
                                                    <span style={{ position: 'relative', color: '#8c8c8d' }}>₹ {product?.mrp * product?.quantity}</span>
                                                    <span className="mx-2 fw-bold">₹ {product?.offer_price * product?.quantity}</span>
                                                </p>
                                            </div>
                                            <div className="mx-2">
                                                <div
                                                    className="mb-4 text-end mt-3 cursor-pointer"
                                                    onClick={() => deleteCartItem(product?.id)}
                                                >
                                                    <RiDeleteBin6Line size={23} color="red" />
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                    <button
                                                        disabled={product?.quantity === "1"}
                                                        style={{
                                                            border: "1px solid black",
                                                            width: "30px",
                                                            height: "30px",
                                                        }}
                                                        className="p-1 d-flex align-items-center justify-content-center cursor-pointer"
                                                        onClick={() =>
                                                            updateCartItem(
                                                                cartId,
                                                                product.product_id,
                                                                Number(product.quantity) - 1
                                                            )
                                                        }
                                                    >
                                                        <FaMinus />
                                                    </button>
                                                    <div className="fw-bold fs-6">{product?.quantity}</div>
                                                    <button
                                                        style={{
                                                            border: "1px solid black",
                                                            width: "30px",
                                                            height: "30px",
                                                        }}
                                                        className="p-1 d-flex align-items-center justify-content-center cursor-pointer"
                                                        onClick={() =>
                                                            updateCartItem(
                                                                cartId,
                                                                product.product_id,
                                                                Number(product.quantity) + 1
                                                            )
                                                        }
                                                    >
                                                        <FaPlus />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        )}

                        {cartItems && cartItems?.length === 0 ? (
                            <div className="mt-5" onClick={navigateToHome}>
                                <button className="checkout-btn p-2 cursor-pointer">
                                    Explore Gummies
                                </button>
                            </div>
                        ) : (
                            <div>
                                <div className="d-flex aligh-items-center justify-content-between mb-3">
                                    <div className="fw-semibold text-warning">Total MRP</div>
                                    <div className="fw-semibold text-warning">
                                        ₹ {cartMrp || 0}
                                    </div>
                                </div>
                                <div className="d-flex aligh-items-center justify-content-between mb-3">
                                    <div className="fw-semibold text-success">Discount on MRP</div>
                                    <div className="fw-semibold text-success">
                                        ₹ - {(cartMrp - offer_price) || 0}
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex aligh-items-center justify-content-between mb-3">
                                    <div className="fw-semibold text-primary">Grand Total</div>
                                    <div className="fw-semibold text-primary">
                                        ₹ {offer_price || 0}
                                    </div>
                                </div>
                                <button
                                    className="checkout-btn p-2 cursor-pointer"
                                    onClick={handleNavigation}
                                >
                                    BUY NOW
                                </button>
                            </div>
                        )}
                    </OffcanvasBody>
                </Offcanvas>

                <Navbar
                    collapseOnSelect
                    expand="lg"
                    className="navbar navbar-expand-lg sleepzzz-navbar navbar-light bg-light bg-body-tertiary"
                    id="navbar"
                >
                    <NavbarToggle
                        aria-controls="responsive-navbar-nav"
                        className="left-margin"
                    />
                    <NavbarBrand className="navbar-brand sleepzz-logo color-white sleepzzz-brand-name fw-semibold">
                        Sleepz<span style={{ fontSize: "26px" }}>z</span>
                        <span style={{ fontSize: "24px" }}>z</span>{" "}
                    </NavbarBrand>
                    <NavbarCollapse
                        id="responsive-navbar-nav"
                        className="collapse navbar-collapse justify-content-end"
                    >
                        <ul className="navbar-nav text-right mx-3 mx-lg-0">
                            <li className="nav-item d-flex justify-content-end">
                                {pathName !== "/" ? (
                                    <Link
                                        to="/"
                                        className=" decoration-text-none nav-link color-white mr-sm-2"
                                    >
                                        {" "}
                                        Benefits
                                    </Link>
                                ) : (
                                    <a
                                        href="#benefits"
                                        className="decoration-text-none nav-link color-white mr-sm-2"
                                    >
                                        Benefits
                                    </a>
                                )}
                            </li>
                            <li className="nav-item d-flex justify-content-end">
                                {pathName !== "/" ? (
                                    <Link
                                        to="/"
                                        className="decoration-text-none nav-link color-white"
                                    >
                                        {" "}
                                        Ingredients
                                    </Link>
                                ) : (
                                    <a
                                        href="#ingredients"
                                        className="decoration-text-none nav-link color-white"
                                    >
                                        Ingredients
                                    </a>
                                )}
                            </li>
                            <li className="nav-item d-flex justify-content-end">
                                {pathName !== "/" ? (
                                    <Link
                                        to="/"
                                        className="decoration-text-none nav-link color-white"
                                    >
                                        {" "}
                                        Effects
                                    </Link>
                                ) : (
                                    <a
                                        href="#effects"
                                        className="decoration-text-none nav-link color-white "
                                    >
                                        Effects
                                    </a>
                                )}
                            </li>
                            <li className="nav-item d-flex justify-content-end">
                                {pathName !== "/" ? (
                                    <Link
                                        to="/"
                                        className="decoration-text-none nav-link color-white"
                                    >
                                        {" "}
                                        Awards
                                    </Link>
                                ) : (
                                    <a
                                        href="#awards"
                                        className="decoration-text-none nav-link color-white "
                                    >
                                        Awards
                                    </a>
                                )}
                            </li>
                            <li className="nav-item d-flex justify-content-end">
                                {pathName !== "/" ? (
                                    <Link
                                        to="/"
                                        className="decoration-text-none nav-link color-white"
                                    >
                                        {" "}
                                        FAQ
                                    </Link>
                                ) : (
                                    <a
                                        href="#faq"
                                        className="decoration-text-none nav-link color-white "
                                    >
                                        FAQ
                                    </a>
                                )}
                            </li>
                            <li className="nav-item d-flex justify-content-end">
                                <NavLink to="/blogs" className="nav-link">
                                    <Link to="/blogs" className="decoration-text-none">
                                        Blogs
                                    </Link>
                                </NavLink>
                            </li>
                            <li className="nav-item d-flex justify-content-end">
                                <NavLink to="#" className="nav-link">
                                    <Link to="/contact" className="decoration-text-none">
                                        Contact
                                    </Link>
                                </NavLink>
                            </li>
                        </ul>
                    </NavbarCollapse>
                </Navbar>

            </header >

            {openLoginModal && (
                <LoginWithOtp
                    open={openLoginModal}
                    setOpen={setOpenLoginModal}
                    openPasswordModal={handleOpenLoginWithPassword}
                    setOpenSignUpModal={setOpenSignUpModal}
                    getUserData={getUserData}
                />
            )
            }

            {
                openSignUpModal && (
                    <Modal
                        show={openSignUpModal}
                        onHide={() => setOpenSignUpModal(false)}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body
                            style={{
                                background: "#140730",
                                color: "white",
                                borderRadius: "8px",
                            }}
                        >
                            <div className="mb-4">
                                <h3 className="text-center">Create Account</h3>
                            </div>
                            <form onSubmit={createNewUser}>
                                <div className="login-body">
                                    <div>
                                        <FormGroup className="mb-2">
                                            <FormLabel>Name</FormLabel>
                                            <FormControl
                                                placeholder="Enter Name"
                                                className="p-2"
                                                name="name"
                                                value={signUpData?.name}
                                                onChange={handleSignUpInputChange}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <FormLabel>Mobile No</FormLabel>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="phone"
                                                placeholder="Enter Number"
                                                aria-describedby="basic-addon2"
                                                className="p-2"
                                                name="mobile"
                                                value={signUpData?.mobile}
                                                onChange={handleSignUpInputChange}
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                id="button-addon2"
                                                style={{
                                                    color: "#F4861E",
                                                    background: "#bcbcbd",
                                                    border: "none",
                                                }}
                                                onClick={sendSignUpOtp}
                                                disabled={isOtpSending || signUpData?.mobile?.length < 10}
                                            >
                                                {isOtpSending ? (
                                                    <p
                                                        className="text-center white-text my-auto"
                                                        style={{ color: "#F4861E" }}
                                                    >
                                                        {countdown} sec
                                                    </p>
                                                ) : (
                                                    <p className="my-auto" style={{ color: "#F4861E", fontSize: "14px" }}>
                                                        Send OTP
                                                    </p>
                                                )}
                                            </Button>
                                        </InputGroup>
                                    </div>
                                    <div>
                                        <FormLabel>Enter OTP</FormLabel>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Enter OTP"
                                                aria-describedby="basic-addon2"
                                                className="p-2"
                                                name="otp"
                                                value={signUpData?.otp}
                                                onChange={handleSignUpInputChange}
                                            />
                                        </InputGroup>
                                        <div>
                                            <p
                                                className="password-switch-text"
                                                style={{ textAlign: "right" }}
                                                onClick={sendSignUpOtp}
                                            >
                                                Resend OTP
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FormGroup className="mb-2">
                                            <FormLabel>Add Password</FormLabel>
                                            <FormControl
                                                placeholder="Enter Password"
                                                type="password"
                                                className="p-2"
                                                name="password"
                                                value={signUpData?.password}
                                                onChange={handleSignUpInputChange}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div style={{ marginTop: "12px" }}>
                                        <FormGroup className="mb-2 mt-2">
                                            <FormLabel>Enter Email (Optional)</FormLabel>
                                            <FormControl
                                                placeholder="Enter Email"
                                                className="p-2"
                                                name="email"
                                                value={signUpData?.email}
                                                onChange={handleSignUpInputChange}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="text-center mt-4 mb-4">
                                    <PrimaryButton title="SIGN UP" isLoading={isSignnedUp} />
                                </div>
                            </form>
                            <div>
                                <p className="text-center">
                                    Have an account?{" "}
                                    <span
                                        className="password-switch-text"
                                        onClick={() => {
                                            setOpenLoginModal(true);
                                            setOpenSignUpModal(false);
                                        }}
                                    >
                                        Login Instead
                                    </span>{" "}
                                </p>
                            </div>
                        </Modal.Body>
                    </Modal>
                )
            }

            {
                openUpdateProfileModal && (
                    <UpdateProfile
                        openUpdateProfileModal={openUpdateProfileModal}
                        setOpenUpdateProfileModal={setOpenUpdateProfileModal}
                    />
                )
            }

            {
                openLoginWithPasswordModal && (
                    <LoginWithPassword
                        open={openLoginWithPasswordModal}
                        setOpen={setopenLoginWithPasswordModal}
                        setOpenLoginModal={setOpenLoginModal}
                        getUserData={getUserData}
                        getAddressList={getAddressList}
                    />
                )
            }

            {
                openChangePasswordModal && (
                    <ChangePassword
                        openChangePasswordModal={openChangePasswordModal}
                        setOpenChangePasswordModal={setOpenChangePasswordModal}
                    />
                )
            }
        </>
    );
};

export default NavbarComponent;
