import React from "react";
import './EffectSection.css'

const EffectSection = () => {
    return (
        <main className="effects-section" id="effects">
            <div className="container fluid">
                <div className="row">
                    <div className="col-12 text-center">
                        <img src="/assets/eyes.png" className="eyes" alt="" />
                    </div>
                    <div className="col-12 text-center">
                        <h2>Sleep Deprivation harms your body more than just feeling drowsy, & fatigue</h2>
                    </div>
                </div>
                <div className="row cards-row d-flex ">
                    <div className="col-6 col-md-4 mb-3 cards "  >
                        <img src="/assets/weakened-immunity.png" alt="" />
                    </div>
                    <div className="col-6 col-md-4 cards mb-3 ">
                        <img src="/assets/diabetes-card.png" alt="" />
                    </div>
                    <div className="col-6 col-md-4 cards ">
                        <div className="row mt-3">
                            <div className="col-12 p-0">
                                <img src="/assets/drive.png" alt="" />
                            </div>
                            <div className="col-12 mt-3 p-0">
                                <img src="/assets/blood-pressure.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 cards mb-3 ">
                        <img src="/assets/memory-issues.png" alt="" />
                    </div>
                    <div className="col-6 col-md-4 cards ">
                        <div className="row mt-1">
                            <div className="col-12 p-0">
                                <img src="/assets/accident-risk.png" alt="" />
                            </div>
                            <div className="col-12 mt-3 p-0">
                                <img src="/assets/think-and-concentrate.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 cards mb-3 ">
                        <img src="/assets/heart-dieases.png" alt="" />
                    </div>

                    <div className="col-12 col-md-6 cards mb-3 mb-md-0 ">
                        <img src="/assets/mood-changes.png" className="special-card" alt="" />
                    </div>
                    <div className="col-12 col-md-6 cards">
                        <img src="/assets/weight-gain.png" className="special-card" alt="" />
                    </div>
                    <div className="col-11 col-md-10 mx-auto text-center cards">
                        <h5 className="effect-description">The long-term effects of sleep deprivation are real. Your body needs sleep, just as it needs air
                            and food to function at its best. During sleep, your body heals itself and restores its chemical
                            balance. Your brain forges new thought connections and helps memory retention.
                            Without enough sleep, your brain and body systems won’t function normally. It can also
                            dramatically lower your quality of life.
                        </h5>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EffectSection;
