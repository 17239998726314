import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { clearCart } from '../../state/cartSlice';


const CodOrder = ({ openCODModal, setOpenCODModal, confirmOrderDetails, isCODOrderConfirmed, isOrderVerified, }) => {
    const { cartMrp, offer_price } = useSelector(state => state.cart)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <Modal
            show={openCODModal}
            onHide={() => {
                dispatch(clearCart())
                navigate("/");
                localStorage.removeItem('cart_id')
                setOpenCODModal(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalBody>
                <div className='mb-5'>
                    <h4 className='text-center mt-3 mb-3'>Your order placed successfully. Your invoice number is <span className='text-primary'>#{confirmOrderDetails?.order?.invoice_no}</span> </h4>
                </div>
                <div className='d-flex'>
                    {/* LEFT DIV  */}
                    <div style={{ width: '50%' }}>

                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>ORDER DETAILS</th>
                                    <th>SHIPPING DETAILS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span className='fw-semibold'>Date:</span> {confirmOrderDetails?.order?.created_at.split(' ')[0]}</td>
                                    <td><i className='fw-semibold'>{confirmOrderDetails?.order?.customer_name}</i></td>
                                </tr>
                                <tr>
                                    <td><span className='fw-semibold'>Invoice:</span>  <i className='text-primary'>
                                        {confirmOrderDetails?.order?.invoice_no}</i></td>
                                    <td> {confirmOrderDetails?.order?.address_line_1}</td>
                                </tr>
                                <tr>
                                    {
                                        isCODOrderConfirmed ? <td><span className='fw-semibold'>Order Status: </span> <span className='text-success'>{confirmOrderDetails?.order?.order_status}</span></td> : isOrderVerified ? <td><span className='fw-semibold'>Order Status: </span> <span className='text-success'>PAID</span></td> : <td></td>
                                    }
                                    <td>{confirmOrderDetails?.order?.address_line_2}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{confirmOrderDetails?.order?.locality}, {confirmOrderDetails?.order?.city}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{confirmOrderDetails?.order?.state} , {confirmOrderDetails?.order?.pincode}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='fw-semibold'>+91 {confirmOrderDetails?.order?.customer_contact} </td>
                                </tr>
                            </tbody>
                        </Table>

                        <Table striped bordered hover>
                            <thead>
                                <tr className='text-center'>
                                    <th colSpan={2}>PAYMENT & TRANSACTION DETAILS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='fw-bold'>Payment Mode: </td>
                                    <td className='fw-bold'>{confirmOrderDetails?.order?.payment_mode}</td>
                                </tr>
                                <tr>
                                    <td className='fw-bold'>Payment Status: </td>
                                    <td className='fw-bold'>{confirmOrderDetails?.order?.payment_status
                                    }</td>
                                </tr>
                                <tr>
                                    <td className='fw-bold'>Order Amount:</td>
                                    <td className='fw-bold'>₹ {confirmOrderDetails?.order?.grand_total}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    {/* RIGHT DIV  */}

                    <div style={{ width: '50%', border: '1px solid #bfbfbf' }}>
                        <div style={{ borderBottom: '1px solid #bfbfbf' }} className='p-1 mb-2'>
                            <h4 className='text-center'>Product details</h4>
                        </div>
                        {
                            confirmOrderDetails?.order?.item_list && confirmOrderDetails?.order?.item_list.map((item) =>
                                <div key={item?.id} className='mx-2 mb-3'>
                                    <div className='d-flex gap-1'>
                                        <div style={{ width: '35%' }}>
                                            <img width="100%" src={item?.product_image_path} alt="product-image" />
                                        </div>
                                        <div className='mt-3'>
                                            <h5><span className='text-primary'>{item?.product_name}</span> </h5>
                                            <p className='mt-1'>{item?.content_qty} Gummies</p>
                                            <small className='text-warning'>{item?.variant_title}</small>
                                            <p>Qty: {item?.quantity}</p>
                                            <div className='mt-3'>
                                                <h2 className="ml-xl-2" style={{ position: 'relative' }}>
                                                    <span>₹ {item?.rate}</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            )
                        }

                        <Table>
                            <thead>
                                <tr>
                                    <th colSpan={2}>Order Summary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='fw-semibold'>Sub Total</td>
                                    <td className='text-end fw-semibold'>₹ {cartMrp}</td>
                                </tr>
                                <tr>
                                    <td className='fw-semibold text-warning'>Offer Price</td>
                                    <td className='text-end fw-semibold text-warning'>₹ {offer_price}</td>
                                </tr>
                                <tr >
                                    <td className='text-success fw-semibold'>Your Savings</td>
                                    <td className='text-success text-end fw-semibold'>₹ {cartMrp - offer_price}</td>
                                </tr>
                                <tr>
                                    <td className='fw-semibold'>Delivery Charges</td>
                                    <td className='text-end fw-semibold'>FREE</td>
                                </tr>
                                <tr>
                                    <td className='text-primary fw-semibold'>GRAND TOTAL</td>
                                    <td className='text-primary text-end fw-semibold'>₹ {confirmOrderDetails?.order?.grand_total}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => {
                    dispatch(clearCart())
                    navigate("/");
                    localStorage.removeItem('cart_id')
                    setOpenCODModal(false);
                }}>CLOSE</Button>
            </ModalFooter>
        </Modal>
    )
}

export default CodOrder
