import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SecondBuyNow.css";

const SecondBuyNow = () => {
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(false);

  const navigateToBuyNow = () => {
    navigate("/sleepzzz-pack-of-1");
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      const threshold = window.innerHeight;
      setShowBanner(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section>
        <div className="d-flex mb-md-5 p-md-1">
          <div className="w-50 pt-5 left-div">
            <h2>Your Deep Sleep is Just a Gummie Away</h2>
            <div className="mt-3 d-md-block d-lg-block d-none">
              <img
                src="assets/money-back-garuntee.png"
                className="w-100"
                alt=""
              />
            </div>
            <div className="d-flex align-items-center gap-md-3 gap-sm-1 mt-2 money-guarantee d-md-flex d-lg-flex d-none">
              <div className="bottom-price" style={{ position: "relative" }}>
                <h2 className="price">
                  <span style={{ position: "relative", color: "#8c8c8d" }}>
                    1290
                  </span>{" "}
                  <span className="offer-price">990</span>
                </h2>
              </div>
              <div className="p-0 btn-div">
                <button
                  className="btn btn-sm buy-btn w-100"
                  onClick={navigateToBuyNow}
                >
                  BUY NOW
                </button>
              </div>
            </div>
          </div>

          <div className="w-50 right-div">
            <img
              src="assets/bottle-front-image-main.png"
              className="bottle"
              alt=""
            />
          </div>
        </div>

        <div className="d-md-none d-lg-none d-block p-2">
          <div className="mt-3">
            <img
              src="assets/money-back-garuntee.png"
              className="w-100"
              alt=""
            />
          </div>
          <div className="d-flex align-items-center gap-md-3 gap-sm-1 mt-2 money-guarantee">
            <div className="bottom-price" style={{ position: "relative" }}>
              <h2 className="price">
                <span style={{ position: "relative", color: "#8c8c8d" }}>
                  1290
                </span>{" "}
                <span className="offer-price">990</span>
              </h2>
            </div>
            <div className="p-0 btn-div">
              <button
                className="btn btn-sm buy-btn w-100"
                onClick={navigateToBuyNow}
              >
                BUY NOW
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className={`buy-now-desktop-banner ${showBanner ? 'visible' : 'hidden'}`}>
        <div className="">
          <div className="row">
            <div className="col-md-3 col-lg-3 offset-lg-1 text-center">
              <img src="./assets/uplife_sleepzzz.png" className="bottle-img d-block" alt="" />
            </div>
            <div className="col-md-5 col-lg-4 text-center my-auto money-back-garuntee-div ">
              <img src="./assets/money-back-garuntee.png" className="offer-img d-block w-100" alt="" />
            </div>
            <div className='d-flex col-md-4 align-items-center gap-md-3 gap-sm-1 mt-2  money-guarantee d-lg-block d-md-block d-none '>
              <div className="bottom-price mx-md-5 mx-lg-4" style={{ position: 'relative' }}>
                <h2 className="price"><span style={{ position: 'relative', color: '#8c8c8d' }}>1290</span> <span className="offer-price">990</span></h2>
              </div>
              <div className="p-0 btn-div">
                <button className="btn btn-sm buy-btn w-100" onClick={navigateToBuyNow}>BUY NOW</button>
              </div>
            </div>

            <div className='d-md-none d-lg-none d-block p-2'>
              <div className='d-flex align-items-center justify-content-end gap-md-3 gap-sm-1 mt-2 money-guarantee'>
                <div className="bottom-price" style={{ position: 'relative' }} >
                  <h2 className="price"><span style={{ position: 'relative', color: '#8c8c8d' }}>1290</span> <span className="offer-price">990</span></h2>
                </div>
                <div className="p-0 btn-div" style={{ width: '100px' }}>
                  <button className="btn btn-sm buy-btn w-100" onClick={navigateToBuyNow}>BUY NOW</button>
                </div>
              </div>
            </div>

            <div className='row d-lg-none d-md-none d-flex'>
              <div className="col-4 offset-lg-1 text-center">
                <img src="./assets/uplife_sleepzzz.png" className="bottle-img d-block" alt="" />
              </div>
              <div className='col-4'>
                <div className='col-md-4 gap-md-3 gap-sm-1 mt-2  money-guarantee d-md-flex d-lg-flex d-none'>
                  <div className="bottom-price" style={{ position: 'relative' }}>
                    <h2 className="price"><span style={{ position: 'relative', color: '#8c8c8d' }}>1290</span> <span className="offer-price">990</span></h2>
                  </div>
                  <div className="p-0 btn-div">
                    <button className="btn btn-sm buy-btn w-100" onClick={navigateToBuyNow}>BUY NOW</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecondBuyNow;
