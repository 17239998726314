import React, { useEffect, useState } from 'react'
import axios from '../../api/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import CancelOrderModal from '../../components/CancelOrderModal';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Puff } from 'react-loader-spinner';
import { setOrderDetails } from '../../state/orderSlice';
import { clearCart } from '../../state/cartSlice';
import { loadScript } from '../../utils/razorpay';

const Orders = () => {
    let access_token = localStorage.getItem("access_token");
    const [ordersData, setOrdersData] = useState([]);
    const navigate = useNavigate();
    const [openOrderCancelModal, setOpenOrderCancelModal] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [singleOrderData, setSingleOrderData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentVerified, setIsPaymentVerified] = useState(false);
    const dispatch = useDispatch();
    const [isOrderCancel, setIsOrderCancel] = useState(false);
    const [loading, setLoading] = useState(true);

    const getOrders = async () => {
        try {
            const headers = {
                Authorization: access_token
            }
            const response = await axios.get('v2/orders/list', { headers });
            const ordersList = response?.data?.data?.list
            setOrdersData(ordersList)
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    }

    const convertDateTime = (dateTimeString) => {
        const [datePart, timePart] = dateTimeString.split(' ');
        const [year, month, day] = datePart.split('-');
        const [hours, minutes, seconds] = timePart.split(':');
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
        const formattedTime = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

        return { date: formattedDate, time: formattedTime };
    }

    const handleOpenCancelModal = (data) => {
        setOpenOrderCancelModal(true)
        setOrderId(data.id)
    }

    const navigateToOrderDetails = (orderId) => {
        navigate(`/orders/${orderId}`);
    };

    const reinitiatePayment = async (orderId) => {
        setIsLoading(true)
        try {
            const headers = { Authorization: access_token }
            const data = {
                order_id: orderId,
            }
            const response = await axios.post("v2/orders/payment/reinitiate", data, { headers });
            if (response?.data?.status === true) {
                const responseData = response?.data?.data
                const order_id = responseData?.configuration?.order_id
                handlePayment(responseData, order_id)
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();
                const doc = parser.parseFromString(form_error, "text/html");

                const errorList = Array.from(doc.querySelectorAll('li')).map(li => li.textContent);

                const errorMessage = (
                    <ul>
                        {errorList.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                );

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handlePayment = async (orderData, orderId) => {
        const orderDetails = {
            amount: orderData?.configuration?.amount,
            currency: orderData?.configuration?.display_currency,
            receipt: orderData?.configuration?.receipt_id,
            description: orderData?.configuration?.description,
            order_id: orderId
        };

        const options = {
            key: process.env.RPAY_KEY,
            amount: orderDetails.amount,
            currency: orderDetails.currency,
            description: orderDetails.description,
            order_id: orderDetails?.order_id,
            handler: function (response) {
                handlePaymentVerification(response);
            },
            prefill: {
                name: orderData?.configuration?.prefill?.name,
                email: orderData?.configuration?.prefill?.email,
                contact: orderData?.configuration?.prefill?.contact,
            },
            notes: {
                address: '123, Street Name, City, Country',
            },
            theme: {
                color: orderData?.configuration?.theme?.color,
            },
            modal: {
                ondismiss: function (response) {
                    handlePaymentFailure(orderDetails?.order_id)
                }
            }
        };

        localStorage.removeItem('cart_id')

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    };

    const handlePaymentVerification = async (razorpay) => {
        setIsPaymentVerified(true)
        try {
            let accessToken = localStorage.getItem("access_token")
            const headers = {
                Authorization: accessToken
            }
            const formdata = new FormData();
            formdata.append('rpay_order_id', razorpay?.razorpay_order_id);
            formdata.append('razorpay_payment_id', razorpay?.razorpay_payment_id);
            formdata.append('razorpay_signature', razorpay?.razorpay_signature);

            const response = await axios.post('v2/orders/payment/verify', formdata, { headers });
            if (response?.data?.status === true) {
                dispatch(setOrderDetails(response?.data?.data?.order));
                localStorage.removeItem('cart_id')
                dispatch(clearCart())
                setIsPaymentVerified(true);
                navigate('/order-success')
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsPaymentVerified(false)
        }
    }

    const handlePaymentFailure = async (id) => {
        setIsOrderCancel(true)
        try {
            let accessToken = localStorage.getItem("access_token")
            const headers = {
                Authorization: accessToken
            }
            const formdata = new FormData()

            formdata.append('rpay_order_id', id)

            const response = await axios.post('v2/orders/payment/failure', formdata, { headers });
            if (response?.data?.status === true) {
                toast.error(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                })
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsOrderCancel(false)
        }
    }

    useEffect(() => {
        getOrders()
    }, [])

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js")
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error loading Razorpay script:", error);
            });
    }, []);


    if (isPaymentVerified) {
        return (
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ background: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", maxWidth: "300px" }}>
                    <Puff
                        height="80"
                        width="80"
                        color="#7B1FA2"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
                </div>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ background: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", maxWidth: "300px" }}>
                    <Puff
                        height="80"
                        width="80"
                        color="#7B1FA2"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
                </div>
            </div>
        );
    }

    if (isOrderCancel) {
        return (
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ background: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", maxWidth: "300px" }}>
                    <Puff
                        height="80"
                        width="80"
                        color="#7B1FA2"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <main class="history">
                <div className="container banner-container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <a class="d-inline-block pl-3 ">
                                <i class="fa-solid fa-arrow-left"
                                    style={{ fontSize: '20px', color: "#fff" }}
                                ></i>
                            </a>
                            <h5 class="d-inline-block pl-3 pb-2 text-white ">Order History</h5>
                        </div>
                    </div>
                    <div class="row mx-3 d-lg-none">
                        {
                            ordersData && ordersData.map((order) => (
                                <div key={order.id} class="col-12 mx-auto border-radius3 bg-white mt-2 pt-2 mb-4 order-history">
                                    <div class="row border-1 border-bottom p-1 mx-auto">
                                        <div class="col-12 pt-2">
                                            <div class="row border-2 border-bottom p-0">
                                                <div class="col-6 border-2 border-right pl-2 p-0">
                                                    <h6 class="fw-bold">Order ID</h6>
                                                    <h6 class="fw-500">{order?.invoice_no}</h6>
                                                </div>
                                                <div class="col-3 border-2 border-right pl-2 p-0">
                                                    <h6 class="fw-bold">Order Date</h6>
                                                    <h6>{convertDateTime(order?.created_at).date}</h6>
                                                </div>
                                                <div class="col-3 pl-2 p-0">
                                                    <h6 class="fw-bold">Payment</h6>
                                                    <h6>{order?.payment_mode} & <span className={`${order?.payment_status === "PAID" ? 'text-success' : 'text-danger'}`}>{order?.payment_status}</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 border-2 border-bottom pt-2 pb-2 px-1">
                                            <h6 class="fw-bold">Address</h6>
                                            <h6>{order?.address_line_1}, {order?.address_line_2} - {order?.locality}, {order?.city}
                                                <br />
                                                {order?.state}
                                                <br />
                                                {order?.pincode}
                                            </h6>
                                            <h6 class="fw-bold">+91 {order?.customer_contact}</h6>
                                        </div>
                                    </div>
                                    {
                                        order?.order_status === "CONFIRMED" ? <div className="row purple-bg order-history d-flex justify-content-between">
                                            <div className="col-3 text-left my-auto mx-2">
                                                <h5 className="d-inline-block text-light">CONFIRMED</h5>
                                            </div>
                                            <div className="col-3 my-auto">
                                                <h5 className="d-inline-block text-light">
                                                    <h5 className="text-light cursor-pointer btn-warning btn-sm view-btn" onClick={() => navigateToOrderDetails(order.id)}>VIEW ORDER</h5>
                                                </h5>
                                            </div>
                                            <div className="col-3 mx-2">
                                                <h5 className="d-inline-block text-light cursor-pointer  btn-danger btn-sm" onClick={() => handleOpenCancelModal(order)}>CANCEL ORDER</h5>
                                            </div>
                                        </div> : <div className="row bg-danger order-history m-0 py-1">
                                            <div className="col-6 text-left my-auto">
                                                <h5 className="d-inline-block text-light">CANCELLED</h5>
                                            </div>
                                            <div className="col-6 my-auto">
                                                <h5 className="text-light cursor-pointer text-right" onClick={() => navigate('/sleepzzz-pack-of-1')}>ORDER AGAIN</h5>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))
                        }

                    </div>

                    <div className="d-none d-lg-block">
                        {
                            ordersData && ordersData.map((order) => (
                                <div key={order.id} className="border-radius3 bg-white mt-2 pt-2 mb-4 order-history mx-auto cursor-pointer">
                                    <div className="row border-1 border-bottom p-1 mx-1">
                                        <div className="col-md-4 border-1 border-right p-3 pl-4">
                                            <div className="border-1 border-bottom pb-3">
                                                <h6 className="fw-bold">ORDER ID</h6>
                                                <h6 className="fw-500">{order?.invoice_no}</h6>
                                            </div>
                                            <div className="pt-4">
                                                <h5 className="">Total: <span>₹ {order?.grand_total}/-</span></h5>
                                            </div>
                                        </div>
                                        <div className="col-md-4 border-1 border-right p-3">
                                            <h6 className="fw-bold">ADDRESS</h6>
                                            <h6> {order?.address_line_1}, {order?.address_line_2} - {order?.locality}, {order?.city}
                                                <br />
                                                {order?.state}
                                                <br />
                                                {order?.pincode}
                                            </h6>
                                            <h6 className="fw-bold">+91 {order?.customer_contact}</h6>
                                        </div>
                                        <div className="col-md-3 p-3">
                                            <div className="pb-1 border-1 border-bottom">
                                                <h6 className="fw-bold">PAYMENT MODE & STATUS</h6>
                                                <h6>{order?.payment_mode} & <span className={`${order?.payment_status === "PAID" ? 'text-success' : 'text-danger'}`}>{order?.payment_status}</span></h6>
                                            </div>

                                            <div className="pt-4">
                                                <h6 className="fw-bold">DATE</h6>
                                                <h6> {convertDateTime(order?.created_at).date}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        order?.order_status === "CONFIRMED" ? <div className="purple-bg order-history m-0 py-1 d-flex justify-content-between align-items-center">
                                            <div className="text-left my-auto mx-2">
                                                <h5 className="d-inline-block text-light">CONFIRMED</h5>
                                            </div>
                                            {
                                                order?.payment_mode === "ONLINE" && order?.payment_status === "UNPAID" && <div className="text-left my-auto mx-2">
                                                    <h5 className="text-light cursor-pointer btn-sm repayment-btn" onClick={() => reinitiatePayment(order?.id)}>REPAYMENT</h5>
                                                </div>
                                            }
                                            <div>

                                                <h5 className="text-light cursor-pointer btn-warning btn-sm view-btn" onClick={() => navigateToOrderDetails(order.id)}>VIEW ORDER</h5>

                                            </div>
                                            <div className="mx-2">
                                                <h5 className="d-inline-block text-light cursor-pointer  btn-danger btn-sm" onClick={() => handleOpenCancelModal(order)}>CANCEL ORDER</h5>
                                            </div>
                                        </div> : <div className="row bg-danger danger-bg">
                                            <div className="col-6 text-left my-auto">
                                                <h5 className="d-inline-block text-light py-1">CANCELLED</h5>
                                            </div>
                                            <div className="col-6 my-auto">
                                                <h5 className="text-light cursor-pointer text-right" onClick={() => navigate('/sleepzzz-pack-of-1')}>ORDER AGAIN</h5>
                                            </div>
                                        </div>
                                    }

                                </div>
                            ))
                        }
                    </div>
                </div>
            </main>

            {
                openOrderCancelModal && <CancelOrderModal openModal={openOrderCancelModal} setOpenModal={setOpenOrderCancelModal} orderId={orderId} />
            }
        </>
    )
}

export default Orders
