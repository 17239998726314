import React, { useEffect, useRef, useState } from "react";
import FirstBanner from "../../components/firstBanner/FirstBanner";
import BuyNowSection from "../../components/buyNowSection/BuyNowSection";
import EffectSection from "../../components/effectSection/EffectSection";
import BenefitsSection from "../../components/benefitsSection/BenefitsSection";
import HowItWorksSection from "../../components/howItWorks/HowItWorksSection";
import ComparisonSection from "../../components/comparison/ComparisonSection";
import FAQSection from "../../components/faqSection/FAQSection";
import { FaArrowUp } from "react-icons/fa";
import SecondBuyNow from "../../components/secondBuySection/SecondBuyNow";
import { useDispatch } from "react-redux";
import { setIsSelectedPage } from "../../state/addressSlice";

const Home = () => {
  const scrollToTopBtnRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch()

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(setIsSelectedPage(1))
  }, [])

  return (
    <>
      {/* <button
        ref={scrollToTopBtnRef}
        className={`scroll-to-top-btn ${isVisible ? 'visible' : ''}`}
        onClick={scrollToTop}
      >
        <FaArrowUp size={25} />
      </button> */}
      <FirstBanner />
      <BuyNowSection />
      <EffectSection />
      <BenefitsSection />
      <HowItWorksSection />
      <ComparisonSection />
      <FAQSection />
      <SecondBuyNow />
    </>
  );
};

export default Home;
