import axios from "axios";

// DEVELOPMENT URL 
const BASE_URL = 'https://dev.theuplife.in/'  

// PRODUCTION URL 
// const BASE_URL = 'https://apis.theuplife.in/'

export default axios.create({
    baseURL: BASE_URL
})