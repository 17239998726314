import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

const Blogs = () => {
  const [blogsData, setBlogsData] = useState([]);

  const getBlogsData = async () => {
    try {
      const response = await axios(`blogs/get`);

      if (response?.data?.status === true) {
        const data = response?.data?.data?.list;
        setBlogsData(data);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    getBlogsData();
  }, []);

  return (
    <section className="py-4">
      <div className="container">
        <div className="row mb-5">
          {blogsData?.map((blog) => (
            <div className="col-md-12 mb-3 col-lg-4">
              <Link to={`/blogs/${blog?.uri_slug}`}>
                <div className="card shadow">
                  <Image fluid 
                    src={blog?.blog_image_path}
                    alt="Sleeping gummies"
                    className="card-img-top"
                    // style={{ width: "100%", height: "280px" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {blog?.title
                        ? blog.title.slice(0, 30) +
                        (blog.title.length > 30 ? "........" : "")
                        : ""}
                    </h5>
                    <p className="card-text">- {blog?.author}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
