import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { toast } from 'react-toastify';
import { clearCart } from '../state/cartSlice';
import { Puff } from 'react-loader-spinner';
import { FaArrowLeft } from 'react-icons/fa';
import { setOrderDetails } from '../state/orderSlice';

const OrderFailure = () => {
    const { orderId } = useSelector(state => state.order);
    let accessToken = localStorage.getItem("access_token")
    const { orderDetails } = useSelector(state => state.order)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isPaymentVerified, setIsPaymentVerified] = useState(false);
    const [isOrderCancel, setIsOrderCancel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [subTotal, setSubTotal] = useState(0)

    const reinitiatePayment = async () => {
        setIsLoading(true)
        try {
            const headers = { Authorization: accessToken }
            const data = {
                order_id: orderId,
            }
            const response = await axios.post("v2/orders/payment/reinitiate", data, { headers });
            if (response?.data?.status === true) {
                const responseData = response?.data?.data
                const order_id = responseData?.configuration?.order_id
                handlePayment(responseData, order_id)
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();
                const doc = parser.parseFromString(form_error, "text/html");

                const errorList = Array.from(doc.querySelectorAll('li')).map(li => li.textContent);

                const errorMessage = (
                    <ul>
                        {errorList.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                );

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handlePayment = async (orderData, orderId) => {
        const orderDetails = {
            amount: orderData?.configuration?.amount,
            currency: orderData?.configuration?.display_currency,
            receipt: orderData?.configuration?.receipt_id,
            description: orderData?.configuration?.description,
            order_id: orderId
        };

        const options = {
            key: process.env.RPAY_KEY,
            amount: orderDetails.amount,
            currency: orderDetails.currency,
            description: orderDetails.description,
            order_id: orderDetails?.order_id,
            handler: function (response) {
                handlePaymentVerification(response);
            },
            prefill: {
                name: orderData?.configuration?.prefill?.name,
                email: orderData?.configuration?.prefill?.email,
                contact: orderData?.configuration?.prefill?.contact,
            },
            notes: {
                address: '123, Street Name, City, Country',
            },
            theme: {
                color: orderData?.configuration?.theme?.color,
            },
            modal: {
                ondismiss: function (response) {
                    handlePaymentFailure(orderDetails?.order_id)
                }
            }
        };

        localStorage.removeItem('cart_id')

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    };

    const handlePaymentVerification = async (razorpay) => {
        setIsPaymentVerified(true)
        try {
            let accessToken = localStorage.getItem("access_token")
            const headers = {
                Authorization: accessToken
            }
            const formdata = new FormData();
            formdata.append('rpay_order_id', razorpay?.razorpay_order_id);
            formdata.append('razorpay_payment_id', razorpay?.razorpay_payment_id);
            formdata.append('razorpay_signature', razorpay?.razorpay_signature);

            const response = await axios.post('v2/orders/payment/verify', formdata, { headers });
            if (response?.data?.status === true) {
                dispatch(setOrderDetails(response?.data?.data?.order));
                localStorage.removeItem('cart_id')
                dispatch(clearCart())
                setIsPaymentVerified(true);
                navigate('/order-success')
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsPaymentVerified(false)
        }
    }

    const handlePaymentFailure = async (id) => {
        setIsOrderCancel(true)
        try {
            let accessToken = localStorage.getItem("access_token")
            const headers = {
                Authorization: accessToken
            }
            const formdata = new FormData()

            formdata.append('rpay_order_id', id)

            const response = await axios.post('v2/orders/payment/failure', formdata, { headers });
            if (response?.data?.status === true) {
                toast.error(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                })
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsOrderCancel(false)
        }
    }

    const navigateToHome = () => {
        navigate('/')
    }

    useEffect(() => {
        const total = orderDetails?.item_list?.reduce((acc, item) => (acc + (parseInt(item?.rate) * item?.quantity)), 0);
        setSubTotal(total)
    }, []);

    

    if (isPaymentVerified) {
        return (
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ background: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", maxWidth: "300px" }}>
                    <Puff
                        height="80"
                        width="80"
                        color="#7B1FA2"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
                </div>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ background: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", maxWidth: "300px" }}>
                    <Puff
                        height="80"
                        width="80"
                       color="#7B1FA2"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
                </div>
            </div>
        );
    }

    if (isOrderCancel) {
        return (
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ background: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", maxWidth: "300px" }}>
                    <Puff
                        height="80"
                        width="80"
                       color="#7B1FA2"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <Card className='m-5'>
            <CardBody>
                <div className='text-center p-3 mb-4 d-flex'>
                    <div onClick={navigateToHome} className='cursor-pointer'>
                        <FaArrowLeft size={25}/>
                    </div>
                    <h4>Your order payment failed. Your order number is <span className='text-primary'># <i>{orderDetails?.invoice_no}</i></span>. Retry paying with other payment method, any additional amount deduction shall be refunded in 24 to 48 hours.</h4>
                </div>
                <div className='d-flex justify-content-end mt-3 mb-3'>
                    <Button className='w-25 p-2 retry-btn fw-bold' onClick={reinitiatePayment} >RETRY PAYMENT</Button>
                </div>

                <Row>
                    <Col>
                        <div >
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ORDER DETAILS</th>
                                        <th>SHIPPING DETAILS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><span className='fw-semibold'>Date:</span> {orderDetails?.created_at?.split(' ')[0]}</td>
                                        <td><i className='fw-semibold'>{orderDetails?.customer_name}</i></td>
                                    </tr>
                                    <tr>
                                        <td><span className='fw-semibold'>Invoice:</span>  <i className='text-primary'>
                                            {orderDetails?.invoice_no}</i></td>
                                        <td> {orderDetails?.address_line_1}, {orderDetails?.address_line_2}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>{orderDetails?.locality}, {orderDetails?.city}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>{orderDetails?.state} , {orderDetails?.pincode}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="fw-semibold">
                                            {orderDetails?.customer_email}{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className='fw-semibold'>+91 {orderDetails?.customer_contact} </td>
                                    </tr>
                                </tbody>
                            </Table>

                            <Table striped bordered hover style={{ marginTop: '62px' }}>
                                <thead>
                                    <tr className='text-center'>
                                        <th colSpan={2}>PAYMENT & TRANSACTION DETAILS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='fw-semibold'>Payment Mode: </td>
                                        <td className='fw-semibold text-warning'>{orderDetails?.payment_mode}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-semibold'>Payment Status: </td>
                                        <td className='fw-semibold text-danger'>{orderDetails?.payment_status}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-semibold text-primary'>Order Amount:</td>
                                        <td className='text-primary fw-semibold'>₹ {orderDetails?.grand_total}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col>
                        <div style={{ border: '1px solid #bfbfbf' }}>
                            <div style={{ borderBottom: '1px solid #bfbfbf' }} className='p-1 mb-2'>
                                <h6 className='text-center fw-bold p-2'>ORDER SUMMARY</h6>
                            </div>
                            <div style={{ height: '255px', overflow: 'auto' }}>

                                {
                                    orderDetails?.item_list && orderDetails?.item_list.map((item) =>
                                        <div key={item?.id} className='mx-2 mb-3'>
                                            <div className='d-flex align-items-center gap-1'>
                                                <div style={{ width: '25%' }}>
                                                    <img width="100%" src={item?.product_image_path} alt="product-image" />
                                                </div>
                                                <div className='mt-3'>
                                                    <h6><span className='text-primary'>{item?.product_name}</span> </h6>
                                                    <h6 className='mt-1'>{item?.content_qty} Gummies</h6>
                                                    <small className='text-warning'>{item?.variant_title}</small>
                                                    <h6>Qty: {item?.quantity}</h6>
                                                    <div className='mt-1'>
                                                        <h6 className="">
                                                            <span className='text-success'>₹ {item?.rate * item?.quantity}</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                }
                            </div>

                            <Table striped bordered hover>
                                <tbody>
                                    <tr>
                                        <td className='fw-semibold'>Sub Total</td>
                                        <td className='text-end fw-semibold'>₹ {subTotal}</td>
                                    </tr>
                                    <tr >
                                        <td className='text-success fw-semibold'>Discount Amount</td>
                                        {
                                            orderDetails?.discount_amount !== 0 ? <td className='text-success text-end fw-semibold'>₹ {orderDetails?.discount_amount}</td> : <td>0</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td className='fw-semibold'>Delivery Charges</td>
                                        <td className='text-end fw-semibold'>FREE</td>
                                    </tr>
                                    <tr>
                                        <td className='text-primary fw-semibold'>GRAND TOTAL</td>
                                        <td className='text-primary text-end fw-semibold'>₹ {orderDetails?.grand_total}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default OrderFailure
