import React from "react";
import "./ComparisonSection.css";

const ComparisonSection = () => {
    return (
        <section className="comparison">
            <div className="container banner-container">
                <div className="row d-md-none">
                    <div className="col-12 col-md-6 text-center">
                        <div className="row">
                            <div className="col-5 offset-2">
                                <h1 className="text-left">
                                    Sleeping <br />
                                    Pills
                                </h1>
                            </div>
                            <div className="col-4">
                                <img
                                    src="/assets/sleeping-pill.png"
                                    className="img-fluid d-inline-block"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 b-top b-bottom mt-3 py-3">
                                <p>
                                    Sleeping pills are prescription medications that are designed
                                    to help people with insomnia or other sleep disorders fall
                                    asleep and stay asleep.
                                </p>
                            </div>
                            <div className="col-12 b-bottom py-3">
                                <p>
                                    They work by altering the chemicals in the brain that affect
                                    sleep and can be effective for helping people fall asleep more
                                    quickly and stay asleep longer.{" "}
                                </p>
                            </div>
                            <div className="col-12 b-bottom py-3">
                                <p>Require a prescription</p>
                            </div>
                            <div className="col-12 py-3">
                                <p>
                                    However, they can also have side effects, such as grogginess,
                                    dizziness, and dry mouth, and should be used with caution.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 text-center d-md-none my-2" style={{
                        width: "100px",
                        margin: "auto",
                        position: 'relative'
                    }}>
                        <h1
                            className="versus mx-auto"
                        >
                            VS
                        </h1>
                    </div>
                    <div className="col-12 col-md-6 text-center">
                        <div className="row">
                            <div className="col-4 offset-2">
                                <img
                                    src="/assets/sleeping-gummy.png"
                                    className="img-fluid d-inline-block"
                                    alt=""
                                />
                            </div>
                            <div className="col-6 text-left my-auto">
                                <h1 className="d-inline-block">
                                    Sleepzzz <br />
                                    Gummies
                                </h1>
                            </div>
                            <div className="col-12 py-3 b-bottom b-top mt-3">
                                <p>
                                    Sleeping gummies are dietary supplements that contain
                                    ingredients such as melatonin, herbs, and amino acids.{" "}
                                </p>
                            </div>
                            <div className="col-12 py-3 b-bottom">
                                <p>
                                    They are designed to help you relax and fall asleep naturally.
                                </p>
                            </div>
                            <div className="col-12 py-3 b-bottom">
                                <p>Do not require a prescription.</p>
                            </div>
                            <div className="col-12 py-3 b-bottom">
                                <p>No Side effects</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center mt-3 mt-md-5">
                        <p>
                            Warning: It's important to note that neither sleeping gummies nor
                            sleeping pills should be used as a long-term solution for insomnia
                            or other sleep disorders. If you have trouble sleeping, it's
                            important to talk to a healthcare professional to determine the
                            cause of your sleep problems and find an appropriate treatment.
                        </p>
                    </div>
                </div>
                <div className="row d-none d-md-flex">
                    <div className="col-md-6 text-center  my-auto">
                        <div className="row">
                            <div className="col-5 offset-1">
                                <h1 className="d-inline-block text-left">
                                    Sleeping <br />
                                    Pills
                                </h1>
                            </div>
                            <div className="col-5 my-auto">
                                <img
                                    src="/assets/sleeping-pill.png"
                                    className="img-fluid d-inline-block"
                                    alt=""
                                />
                            </div>
                            <div className="my-auto" style={{ width: "10px" }}>
                                <h1 className="d-md-block d-none">VS</h1>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 text-center my-auto">
                        <div className="row">
                            <div className="col-4 offset-2 my-auto">
                                <img
                                    src="/assets/sleeping-gummy.png"
                                    className="img-fluid d-inline-block"
                                    alt=""
                                />
                            </div>
                            <div className="col-6 text-left my-auto">
                                <h1 className="d-inline-block">
                                    Sleepzzz <br />
                                    Gummies
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="row">
                            <div className="col-6 b-bottom b-top b-right py-4">
                                <p>
                                    Sleeping pills are prescription medications that are designed
                                    to help people with insomnia or other sleep disorders fall
                                    asleep and stay asleep.
                                </p>
                            </div>
                            <div className="col-6 b-bottom b-top py-4 pl-5">
                                <p>
                                    Sleeping gummies are dietary supplements that contain
                                    ingredients such as melatonin, herbs, and amino acids.{" "}
                                </p>
                            </div>
                            <div className="col-6 b-bottom b-right py-4">
                                <p>
                                    They work by altering the chemicals in the brain that affect
                                    sleep and can be effective for helping people fall asleep more
                                    quickly and stay asleep longer.{" "}
                                </p>
                            </div>
                            <div className="col-6 b-bottom py-4 pl-5">
                                <p>
                                    They are designed to help you relax and fall asleep naturally.
                                </p>
                            </div>
                            <div className="col-6 b-bottom b-right py-4">
                                <p>Require a prescription</p>
                            </div>
                            <div className="col-6 b-bottom py-4 pl-5">
                                <p>Do not require a prescription.</p>
                            </div>
                            <div className="col-6 b-bottom b-right py-4">
                                <p>
                                    However, they can also have side effects, such as grogginess,
                                    dizziness, and dry mouth, and should be used with caution.
                                </p>
                            </div>
                            <div className="col-6 b-bottom py-4 pl-5">
                                <p>No Side effects</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center mt-4">
                        <p>
                            Warning: It's important to note that neither sleeping gummies nor
                            sleeping pills should be used as a long-term solution for insomnia
                            or other sleep disorders. If you have trouble sleeping, it's
                            important to talk to a healthcare professional to determine the
                            cause of your sleep problems and find an appropriate treatment.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ComparisonSection;
