import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Modal,
    ModalBody,
    Row,
    Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";

const OrderSuccess = () => {
    const navigate = useNavigate();
    const { orderDetails } = useSelector((state) => state.order);
    const [subTotal, setSubTotal] = useState(0);

    const navigateToHome = () => {
        navigate("/");
    };

    useEffect(() => {
        const total = orderDetails?.item_list?.reduce(
            (acc, item) => acc + parseInt(item?.rate) * item?.quantity,
            0
        );
        setSubTotal(total);
    }, []);

    return (
        <Card className="m-5">
            <CardBody>
                <div className="text-center p-3 mb-4 d-flex">
                    <div onClick={navigateToHome} className="cursor-pointer">
                        <FaArrowLeft size={25} />
                    </div>
                    <h4
                        className={`${orderDetails?.payment_mode === "COD" ? "left-margin" : ""
                            }`}
                    >
                        Your order placed successfully. Your order number is{" "}
                        <span className="text-primary">
                            # <i>{orderDetails?.invoice_no}</i>
                        </span>
                        .
                        {orderDetails?.payment_mode === "ONLINE" && (
                            <span>
                                We received your payments and your order will be dispatched
                                immediately.
                            </span>
                        )}
                    </h4>
                </div>
                <Row>
                    <Col>
                        {/* <div> */}
                        <Row>
                            <Col><Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className="w-50">ORDER DETAILS</th>
                                        <th>SHIPPING DETAILS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="fw-semibold">Date:</span>{" "}
                                            {orderDetails?.created_at?.split(" ")[0]}
                                        </td>
                                        <td>
                                            <i className="fw-semibold">
                                                {orderDetails?.customer_name}
                                            </i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="fw-semibold">Invoice:</span>{" "}
                                            <i className="text-primary">{orderDetails?.invoice_no}</i>
                                        </td>
                                        <td>
                                            {orderDetails?.address_line_1},{" "}
                                            {orderDetails?.address_line_2}
                                        </td>
                                    </tr>
                                    <tr>
                                        {
                                            <td>
                                                <span className="fw-semibold">Order Status: </span>{" "}
                                                <span className="text-success">
                                                    {orderDetails?.order_status}
                                                </span>
                                            </td>
                                        }
                                        <td>
                                            {orderDetails?.locality}, {orderDetails?.city}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            {orderDetails?.state} , {orderDetails?.pincode}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="fw-semibold">
                                            {orderDetails?.customer_email}{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="fw-semibold">
                                            +91 {orderDetails?.customer_contact}{" "}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table></Col>

                            <Col>  <Table striped bordered hover style={{ marginTop: '62px' }}>
                                <thead>
                                    <tr className="text-center">
                                        <th colSpan={2}>PAYMENT & TRANSACTION DETAILS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="fw-semibold">Payment Mode: </td>
                                        <td className="fw-semibold text-warning">{orderDetails?.payment_mode}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-semibold">Payment Status: </td>
                                        {
                                            orderDetails?.payment_status === "PAID" ? <td className="fw-semibold text-success">{orderDetails?.payment_status}</td> : <td className="fw-semibold text-danger">{orderDetails?.payment_status}</td>
                                        }

                                    </tr>
                                    <tr>
                                        <td className="text-primary fw-semibold">Order Amount:</td>
                                        <td className="text-primary fw-semibold">
                                            ₹ {orderDetails?.grand_total}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table></Col>




                            {/* </div> */}
                        </Row>
                    </Col>
                    <Col>
                        <div style={{ border: "1px solid #bfbfbf" }}>
                            <div
                                style={{ borderBottom: "1px solid #bfbfbf" }}
                                className="p-1 mb-2"
                            >
                                <h6 className="text-center fw-bold p-2">ORDER SUMMARY</h6>
                            </div>
                            <div style={{ height: '255px', overflow: 'auto' }}>

                                {orderDetails?.item_list &&
                                    orderDetails?.item_list.map((item) => (
                                        <div key={item?.id} className="mx-2 mb-3">
                                            <div className="d-flex align-items-center gap-1">
                                                <div style={{ width: "25%" }}>
                                                    <img
                                                        width="100%"
                                                        src={item?.product_image_path}
                                                        alt="product-image"
                                                    />
                                                </div>
                                                <div>
                                                    <h6>
                                                        <span className="text-primary">
                                                            {item?.product_name}
                                                        </span>{" "}
                                                    </h6>
                                                    <h6 className="mt-1">{item?.content_qty} Gummies</h6>
                                                    <small className="text-warning">
                                                        {item?.variant_title}
                                                    </small>
                                                    <h6>Qty: {item?.quantity}</h6>
                                                    <div className="mt-1">
                                                        <h6 className="">
                                                            <span className="text-success">
                                                                ₹ {item?.rate * item?.quantity}
                                                            </span>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    ))}
                            </div>
                            <Table striped bordered hover>
                                <tbody>
                                    <tr>
                                        <td className="fw-semibold">Sub Total</td>
                                        <td className="text-end fw-semibold">₹ {subTotal}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-success fw-semibold">
                                            Discount Amount
                                        </td>
                                        {orderDetails?.discount_amount !== 0 ? (
                                            <td className="text-success text-end fw-semibold">
                                                ₹ {orderDetails?.discount_amount}
                                            </td>
                                        ) : (
                                            <td>0</td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className="fw-semibold">Delivery Charges</td>
                                        <td className="text-end fw-semibold">FREE</td>
                                    </tr>
                                    <tr>
                                        <td className="text-primary fw-semibold">GRAND TOTAL</td>
                                        <td className="text-primary text-end fw-semibold">
                                            ₹ {orderDetails?.grand_total}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default OrderSuccess;
