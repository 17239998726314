import React from "react";
import './HowItWorksSection.css'

const HowItWorksSection = () => {
    return (
        <section className="how-it-works" id="ingredients">
            <div className="container banner-container mx-auto">
                <div className="row">
                    <div className="col-12 text-center">
                        <img src="assets/sun.png" className="img-fluid pill-image" alt="" />
                        <h1>How it Works?</h1>
                    </div>
                    <div className="col-12 text-center mb-3 mb-lg-5">
                        <p className="text-white">
                            Sleep better, faster with Sleepzzz gummies. Created with affron
                            Which is extracted from saffron. Affron is traditionally used in
                            ancient ayarveda and is now an award winning ingredient in modern
                            day sceince, winning more than 21 awards. The gummies also include
                            Brahmi, Shankapushpi, Lavender, Chamomile, Melatonin & other
                            herbs. just one pop and your off to sleep.{" "}
                        </p>
                    </div>
                </div>
                <div className="row ingredients d-md-none">
                    <div className="col-12 ingredient-card">
                        <div className="card">
                            <div className="card-body pink-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <img
                                            src="assets/affron.png"
                                            className="img-fluid"
                                            alt="affron-image"
                                        />
                                    </div>
                                    <div className="col-8 pl-0">
                                        <h2>Affron</h2>
                                        <ul>
                                            <li>Maintains positive mood</li>
                                            <li>Induces relaxation</li>
                                            <li>Alleviate anxiety, Stress & tension reduction</li>
                                            <li>Improves sleep quality</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 collapse" id="affron-text">
                                        <p>
                                            Affron is a botanical extract from saffron. Saffron,
                                            besides being a spice used for more than 3500 years, has
                                            its deep roots in ancient ayurveda & has been used for
                                            centuries, It is believed to have a range of health
                                            benefits due to its active ingredients, which include
                                            crocin, crocetin, and safranal. And today is being highly
                                            researched, uncovering various benefits. Affron® is a
                                            premium saffron ingredient standardised to 3.5%
                                            Lepticrosalides®, by HPLC, a complex of bioactive
                                            compounds responsible for the beneficial properties and
                                            organoleptic properties of affron®* It is backed by 8
                                            human clinical studies that have shown to improve mood in
                                            healthy consumers with occasional stress and anxiety, and
                                            to improve sleeplessness*Why affron®?USA Award-winner
                                            Ingredient for Cognitive Health 2020.8 clinical studies
                                            published*.Proprietary extraction process AFF®ON
                                            Cool-Tech, which uniquely concentrates and preserves the
                                            bioactives with lower energy use.Genuine Crocus sativus L.
                                            Made in Spain.Lowest dosage: 28 mg/day.Unique
                                            dose-response study in 128 healthy adults*.Clinically
                                            studied in adolescents*.
                                        </p>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            data-toggle="collapse"
                                            data-target="#affron-text"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="affron-text"
                                        >
                                            Read More{" "}
                                            <img
                                                src="assets/read-more-icon.png"
                                                className="read-more-icon"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 ingredient-card">
                        <div className="card">
                            <div className="card-body blue-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <img
                                            src="assets/brahmi.png"
                                            className="img-fluid"
                                            alt="brahmi-image"
                                        />
                                    </div>
                                    <div className="col-8">
                                        <h2>Brahmi</h2>
                                        <ul>
                                            <li>Reduction of stress through</li>
                                            <li> Retention, creation, and </li>
                                            <li>Retrieval of memory</li>
                                            <li>Sound sleep</li>
                                            <li>Reduction of anxiety</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 collapse" id="brahmi-text">
                                        <p>
                                            Brahmi is largely treasured as a revitalising herb used by
                                            Ayurvedic medical practitioners for almost 3000 years.
                                            Brahmi is rich in antioxidants with anti-inflammatory
                                            properties. helps calm the mind, promote clarity of
                                            thought, and memory consolidation.
                                        </p>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            data-toggle="collapse"
                                            data-target="#brahmi-text"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="brahmi-text"
                                        >
                                            Read More{" "}
                                            <img
                                                src="assets/read-more-icon.png"
                                                className="read-more-icon"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 ingredient-card">
                        <div className="card">
                            <div className="card-body blue-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <img
                                            src="assets/shankarpushpi.png"
                                            className="img-fluid"
                                            alt="brahmi-image"
                                        />
                                    </div>
                                    <div className="col-8">
                                        <h2>Shankapushpi </h2>
                                        <ul>
                                            <li>Memory Boosting</li>
                                            <li>HerbsMild relaxan</li>
                                            <li>Calmer mind</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 mt-3 collapse" id="shankapushpi-text">
                                        <p>
                                            Shankhpushpi, commonly known as Morning glory, is a mild
                                            relaxant with the generic name Convolvulus pluricaulis. It
                                            helps to quiet the brain and ease tension and anxiety. It
                                            enhances mental wellness and may aid in the treatment of
                                            depression due to its antidepressant effect. with added
                                            cognitive benefits such as improved memory function and
                                            strong attention.
                                        </p>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            data-toggle="collapse"
                                            data-target="#shankapushpi-text"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="shankapushpi-text"
                                        >
                                            Read More{" "}
                                            <img
                                                src="assets/read-more-icon.png"
                                                className="read-more-icon"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 ingredient-card">
                        <div className="card">
                            <div className="card-body purple-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <img
                                            src="assets/lavender.png"
                                            className="img-fluid"
                                            alt="pentagon-shape"
                                        />
                                    </div>
                                    <div className="col-8">
                                        <h2>Lavender</h2>
                                        <ul>
                                            <li>
                                                In ancient times, lavender was used as a holy herb.
                                                Today, lavender is more than just a fragrant plant. As
                                                it turns out, this herb is also commonly used for
                                                medicinal and therapeutic benefits.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 collapse" id="lavender-text">
                                        <p>
                                            {" "}
                                            The essence of Lavender, a natural sedative, helps in
                                            keeping your body and mind calm. According to Ayurveda,
                                            Vata governs all the movements and actions of the body and
                                            nervous system respectively. Anxiety is mainly caused due
                                            to an imbalance of Vata. Lavender helps to balance Vata
                                            and gives a calming effect to the nervous system. It
                                            increases your time spent in deep sleep, and increases
                                            sleep quality.
                                        </p>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            data-toggle="collapse"
                                            data-target="#lavender-text"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="lavender-text"
                                        >
                                            Read More{" "}
                                            <img
                                                src="assets/read-more-icon.png"
                                                className="read-more-icon"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 ingredient-card">
                        <div className="card">
                            <div className="card-body purple-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <img
                                            src="assets/chamomile.png"
                                            className="img-fluid"
                                            alt="pentagon-shape"
                                        />
                                    </div>
                                    <div className="col-8">
                                        <h2>Chamomile</h2>
                                        <ul>
                                            <li>
                                                Chamomile tea is widely thought to help people relax and
                                                fall asleep. Chamomile is an herb that comes from the
                                                daisy-like flowers of the A-teraceae plant family.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 collapse" id="chamomile-text">
                                        <p>
                                            {" "}
                                            It has been consumed for centuries as a natural remedy for
                                            several health conditions. It contains apigenin, an
                                            antioxidant that binds to certain receptors in your brain
                                            that may promote sleepiness and reduce insomnia, or the
                                            chronic inability to sleep Lower depression Better sleep
                                            Relieves Depression & Anxiety
                                        </p>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            data-toggle="collapse"
                                            data-target="#chamomile-text"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="chamomile-text"
                                        >
                                            Read More{" "}
                                            <img
                                                src="assets/read-more-icon.png"
                                                className="read-more-icon"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 ingredient-card">
                        <div className="card">
                            <div className="card-body purple-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <img
                                            src="assets/melatonin.png"
                                            className="img-fluid"
                                            alt="pentagon-shape"
                                        />
                                    </div>
                                    <div className="col-8">
                                        <h2>Melatonin</h2>
                                        <ul>
                                            <li>
                                                Melatonin is often called the sleep hormone — and for
                                                good reason. Melatonin is a hormone produced naturally
                                                by the pineal gland in the brain that helps regulate
                                                sleep-wake cycles.
                                            </li>
                                        </ul>
                                        <p className="d-none d-md-block">
                                            Research suggests that melatonin supplements may help
                                            improve sleep quality, especially in people with insomnia
                                            or other sleep disorders. Helps regulate sleep-wake
                                            cycles:May improve sleep quality:May have antioxidant
                                            properties:May have anti-inflammatory effects:{" "}
                                        </p>
                                    </div>
                                    <div className="col-12 collapse" id="melatonin-text">
                                        <p className="d-md-none">
                                            Research suggests that melatonin supplements may help
                                            improve sleep quality, especially in people with insomnia
                                            or other sleep disorders. Helps regulate sleep-wake
                                            cycles:May improve sleep quality:May have antioxidant
                                            properties:May have anti-inflammatory effects:
                                        </p>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            data-toggle="collapse"
                                            data-target="#melatonin-text"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="melatonin-text"
                                        >
                                            Read More{" "}
                                            <img
                                                src="assets/read-more-icon.png"
                                                className="read-more-icon"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ingredients d-none d-md-flex mx-5">
                    <div className="col-12 ingredient-card">
                        <div className="card">
                            <div className="card-body pink-card">
                                <div className="row">
                                    <div className="col-6 col-xl-4 text-center">
                                        <img
                                            src="assets/affron.png"
                                            className="affron-img"
                                            alt="affron-image"
                                        />
                                        <ul>
                                            <li>Maintains positive mood</li>
                                            <li>Induces relaxation</li>
                                            <li>Alleviate anxiety, Stress & tension reduction</li>
                                            <li>Improves sleep quality</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-xl-8">
                                        <h2>Affron</h2>
                                        <p>
                                            <p>
                                                Affron is a botanical extract from saffron. Saffron,
                                                besides being a spice used for more than 3500 years, has
                                                its deep roots in ancient ayurveda & has been used for
                                                centuries, It is believed to have a range of health
                                                benefits due to its active ingredients, which include
                                                crocin, crocetin, and safranal. And today is being
                                                highly researched, uncovering various benefits. Affron®
                                                is a premium saffron ingredient standardised to 3.5%
                                                Lepticrosalides®, by HPLC, a complex of bioactive
                                                compounds responsible for the beneficial properties and
                                                organoleptic properties of affron®* It is backed by 8
                                                human clinical studies that have shown to improve mood
                                                in healthy consumers with occasional stress and anxiety,
                                                and to improve sleeplessness*Why affron®?USA
                                                Award-winner Ingredient for Cognitive Health 2020.8
                                                clinical studies published*.Proprietary extraction
                                                process AFF®ON Cool-Tech, which uniquely concentrates
                                                and preserves the bioactives with lower energy
                                                use.Genuine Crocus sativus L. Made in Spain.Lowest
                                                dosage: 28 mg/day.Unique dose-response study in 128
                                                healthy adults*.Clinically studied in adolescents*.
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 ingredient-card">
                        <div className="card">
                            <div className="card-body blue-card">
                                <div className="row">
                                    <div className="col-4 text-center">
                                        <img
                                            src="assets/brahmi.png"
                                            className="ing-img img-fluid"
                                            alt="brahmi-image"
                                        />
                                    </div>
                                    <div className="col-8">
                                        <ul>
                                            <li>Reduction of stress through</li>
                                            <li> Retention, creation, and </li>
                                            <li>Retrieval of memory</li>
                                            <li>Sound sleep</li>
                                            <li>Reduction of anxiety</li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <h2>Brahmi</h2>
                                    </div>
                                    <div className="col-12">
                                        <p>
                                            Brahmi is largely treasured as a revitalising herb used by
                                            Ayurvedic medical practitioners for almost 3000 years.
                                            Brahmi is rich in antioxidants with anti-inflammatory
                                            properties. helps calm the mind, promote clarity of
                                            thought, and memory consolidation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 ingredient-card">
                        <div className="card">
                            <div className="card-body blue-card">
                                <div className="row">
                                    <div className="col-4 text-center">
                                        <img src="assets/shankarpushpi.png" className="ing-img img-fluid" alt="brahmi-image" />
                                    </div>
                                    <div className="col-8">
                                        <ul>
                                            <li>Memory Boosting</li>
                                            <li>HerbsMild relaxan</li>
                                            <li>Calmer mind</li>
                                            <li className="invisible d-none d-lg-block d-xl-none">asd</li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <h2>Shankapushpi </h2>
                                    </div>
                                    <div className="col-12">
                                        <p>Shankhpushpi, commonly known as Morning glory, is a mild relaxant with the generic name Convolvulus pluricaulis. It helps to quiet the brain and ease tension and anxiety. It enhances mental wellness and may aid in the treatment of depression due to its antidepressant effect. with added cognitive benefits such as improved memory function and strong attention.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 ingredient-card">
                        <div className="card mini-card">
                            <div className="card-body purple-card">
                                <div className="row text-center">
                                    <div className="col-12">
                                        <img src="assets/lavender.png" className="ing-img img-fluid" alt="pentagon-shape" />
                                    </div>
                                    <div className="col-12">
                                        <h2>Lavender</h2>
                                    </div>
                                    <div className="col-12">
                                        <p>In ancient times, lavender was used as a holy herb. Today, lavender is more than just a fragrant plant. As it turns out, this herb is also commonly used for medicinal and therapeutic benefits.  The essence of Lavender, a natural sedative, helps in keeping your body and mind calm. According to Ayurveda, Vata governs all the movements and actions of the body and nervous system respectively. Anxiety is mainly caused due to an imbalance of Vata. Lavender helps to balance Vata and gives a calming effect to the nervous system. It increases your time spent in deep sleep, and increases sleep quality.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 ingredient-card">
                        <div className="card mini-card">
                            <div className="card-body purple-card">
                                <div className="row text-center">
                                    <div className="col-12">
                                        <img src="assets/chamomile.png" className="ing-img img-fluid" alt="pentagon-shape" />
                                    </div>
                                    <div className="col-12">
                                        <h2>Chamomile</h2>
                                    </div>
                                    <div className="col-12">
                                        <p>Chamomile tea is widely thought to help people relax and fall asleep. Chamomile is an herb that comes from the daisy-like flowers of the
                                            A-teraceae plant family. It has been consumed for centuries as a natural remedy for several health conditions. It contains apigenin, an antioxidant that binds to certain receptors in your brain that may promote sleepiness and reduce insomnia, or the chronic inability to sleep Lower depression Better sleep Relieves Depression & Anxiety</p>
                                        <br /><br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 ingredient-card">
                        <div className="card mini-card">
                            <div className="card-body purple-card">
                                <div className="row text-center">
                                    <div className="col-12">
                                        <img src="assets/melatonin.png" className="ing-img img-fluid" alt="pentagon-shape" />
                                    </div>
                                    <div className="col-12">
                                        <h2>Melatonin</h2>
                                    </div>
                                    <div className="col-12">
                                        <p> Melatonin is often called the sleep hormone — and for good reason. Melatonin is a hormone produced naturally by the pineal gland in the brain that helps regulate sleep-wake cycles. Research suggests that melatonin supplements may help improve sleep quality, especially in people with insomnia or other sleep disorders. Helps regulate sleep-wake cycles:May improve sleep quality:May have antioxidant properties:May have anti-inflammatory effects: </p>
                                        <br /><br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorksSection;
