import React, { useEffect, useState } from 'react'
import axios from '../../api/axios'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setCartCount, setCartId, setCartItems, setIsCartOpen } from '../../state/cartSlice';
import { Col, Row, Spinner } from 'react-bootstrap';
import { setIsSelectedPage } from '../../state/addressSlice';
import Slider from 'react-slick';
import { Puff } from 'react-loader-spinner';
import "./BuyNow.css"

const BuyNow = () => {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    const mobileSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    }

    const divStyle = {
        width: '97%',
        height: '140px',
        padding: '10px 30px 0px 20px',
        background: "#ffffff",
        color: 'black',
        borderRadius: '8px'
    };

    const mobileDivStyle = {
        width: '97%',
        height: '140px',
        padding: '10px 10px 0px 10px',
        background: "#ffffff",
        color: 'black',
        borderRadius: '8px',
    }

    const mobilePicsSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    }

    const picsDiv = {
        width: '96%',
        color: 'black',
        borderRadius: '8px',
        marginTop: '10px'
    }
    let cartIdFromLocalStorage = localStorage.getItem('cart_id');
    const [productsData, setProductsData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({
        product: "",
        quantity: 1
    })
    const [activeCard, setActiveCard] = useState(null);
    const dispatch = useDispatch();
    const [isAdded, setIsAdded] = useState(false);
    const [sideImages, setSideImages] = useState([
        'assets/uplife_sleepzzz.png',
        'assets/bottle-side-1.png',
        'assets/bottle-side-2.png',
        'assets/bottle-side-3.png'
    ]);
    const [isLoading, setIsLoading] = useState(false)

    const handleImageClick = (index) => {
        const newSideImages = [...sideImages];
        const mainImage = newSideImages[0];
        newSideImages[0] = newSideImages[index];
        newSideImages[index] = mainImage;
        setSideImages(newSideImages);
    };

    const cardClassName = (productId) => {
        return productId === activeCard ? 'cursor-pointer card-selected' : 'cursor-pointer';
    };

    const getAllProductsData = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get('products');
            if (response?.data?.status === true) {
                const data = response?.data?.data?.products
                setProductsData(data);

                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: "view_item_list",
                    ecommerce: {
                        item_list_id: "uplife_products",
                        item_list_name: "Up life products",
                        items: data?.map((item, index) => ({
                            item_id: item?.id,
                            item_name: item?.product_name,
                            affiliation: "UP LIFE",

                            index: index,
                            item_brand: "UP LIFE",
                            item_variant: item?.variant_title,
                            price: item?.offer_price,
                        }))
                    }
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false)
        }
    }

    const selectProduct = (productId) => {
        setActiveCard(productId);
        setSelectedProduct((prev) => ({ ...prev, product: productId }))
    }

    const addToCart = async () => {
        setIsAdded(true)
        try {
            if (cartIdFromLocalStorage) {
                selectedProduct.cart_id = cartIdFromLocalStorage
            }
            const response = await axios.post('cart/add', selectedProduct);
            if (response?.data?.status === true) {
                const cartItems = response?.data?.data?.cart?.item_list;
                dispatch(setCartId(response?.data?.data?.cart?.id))
                dispatch(setCartItems(cartItems));
                dispatch(setCartCount(cartItems?.length));
                dispatch(setIsCartOpen(true))
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "light",
                });
                const cart_id = response?.data?.data?.cart?.id;
                localStorage.setItem('cart_id', cart_id);

                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        currency: "INR",
                        value: cartItems?.grand_total,
                        items: cartItems?.map((item, index) => ({
                            item_id: item?.id,
                            item_name: item?.product_name,
                            affiliation: "UP LIFE",
                            discount: cartItems?.discount_amount,
                            index: index,
                            item_brand: "UP LIFE",
                            item_variant: item?.variant_title,
                            price: item?.offer_price,
                            quantity: item?.quantity,
                        })),
                    }
                });

                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: "view_cart",
                    ecommerce: {
                        currency: "INR",
                        value: cartItems?.grand_total,
                        items: cartItems?.map((item, index) => ({
                            item_id: item?.id,
                            item_name: item?.product_name,
                            affiliation: "UP LIFE",
                            discount: cartItems?.discount_amount,
                            index: index,
                            item_brand: "UP LIFE",
                            item_variant: item?.variant_title,
                            price: item?.offer_price,
                            quantity: item?.quantity
                        }))
                    }
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "light",
                });
            }
        } finally {
            setIsAdded(false)
        }
    }

    const calculateDiscountPercentage = (originalPrice, discountedPrice) => {
        if (typeof originalPrice !== 'number' || typeof discountedPrice !== 'number') {
            return 'Invalid input. Please provide valid numbers.';
        }

        if (discountedPrice >= originalPrice) {
            return 'Discounted price must be less than the original price.';
        }

        const discount = originalPrice - discountedPrice;
        const discountPercentage = (discount / originalPrice) * 100;
        return discountPercentage.toFixed(1);
    }

    useEffect(() => {
        getAllProductsData()
    }, [])

    useEffect(() => {
        setActiveCard(productsData[0]?.id);
        setSelectedProduct((prev) => ({ ...prev, product: productsData[0]?.id }));
    }, [productsData[0]?.id]);

    useEffect(() => {
        dispatch(setIsSelectedPage(1))
    }, []);


    if (isLoading) {
        return (
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ background: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", maxWidth: "300px" }}>
                    <Puff
                        height="80"
                        width="80"
                        color="#7B1FA2"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='d-flex py-4 d-lg-flex d-md-none d-none' >
                {/* FIRST DIV  */}
                <div style={{ width: '43%' }}>
                    <div className='d-flex bottle-imgs-main-box'>
                        <div className='d-flex flex-column gap-3 justify-content-center small-imgs-main-box mx-3'>
                            {sideImages.slice(1, 4).map((image, index) => (
                                <div key={index} className='mt-4 small-img-div cursor-pointer' onClick={() => handleImageClick(index + 1)}>
                                    <img
                                        className={`img-fluid small_img h-100 ${image === 'assets/uplife_sleepzzz.png' ? 'first-image' : ''}`}
                                        src={image}
                                        alt={`Image ${index}`}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='d-flex justify-content-center cursor-pointer align-items-center big-img-main-box'>
                            <img
                                className="img-fluid main_img main-bottle-image"
                                src={sideImages[0]}
                                alt="Main Image"
                            />
                        </div>
                    </div>
                </div>
                {/* SECOND DIV  */}
                <div style={{ width: '57%' }}>
                    <div className='p-3'>
                        <h3 className='white-text'>Up Life <br /> Sleepz<span className='fw-bold' style={{ fontSize: '26px' }}>z</span><span className='fw-bold' style={{ fontSize: '24px' }}>z</span> <small>Gummies (30 Soft Chews)&nbsp;&nbsp;</small> <img src="assets/veg-icon.png" className="veg-icon" alt="" /></h3>
                        <img src="assets/white-rating-stars.png" className="img-fluid d-inline-block mr-1 mb-2" alt="" />
                        <span className='white-text' style={{ marginLeft: '7px' }}>35+ Ratings</span>
                        <div className='horizontal-line mt-2'></div>
                        <p className="mt-2 white-text">Select Qty</p>
                    </div>
                    <div className='slider-container'>
                        <Slider {...settings}>
                            <div >
                                <div style={divStyle} className={cardClassName(productsData[0]?.id)} onClick={() => selectProduct(productsData[0]?.id)}>
                                    <h5 className='fw-semibold'>1 Month Pack</h5>
                                    <p className='light-font-weight'>{productsData[0]?.content_qty} Gummies</p>
                                    <h2 className="product-price" style={{ position: 'relative', fontSize: '18px', marginTop: '-10px' }}><span style={{ position: 'relative', color: '#8c8c8d', fontSize: '14px' }}>₹ {productsData[0]?.mrp} </span> <span className="offer-price fw-semibold"> ₹ {productsData[0]?.offer_price}</span></h2>
                                    <p style={{ fontSize: '14px' }} className="savings offer-percent">You save ₹{productsData[0]?.mrp - productsData[0]?.offer_price} <span className="offer-percent" style={{ fontSize: '12px' }}> ({calculateDiscountPercentage(parseInt(productsData[0]?.mrp), parseInt(productsData[0]?.offer_price))}% off)</span></p>
                                    {activeCard === productsData[0]?.id && <div className="selected-text text-center">SELECTED</div>}
                                </div>
                            </div>
                            <div >
                                <div className={cardClassName(productsData[2]?.id)} onClick={() => selectProduct(productsData[2]?.id)} style={{ ...divStyle, position: 'relative' }}>
                                    <h5 className='fw-semibold'>2 Month Pack</h5>
                                    <p className='light-font-weight'>{productsData[2]?.content_qty} Gummies</p>
                                    <h2 className="product-price" style={{ position: 'relative', fontSize: '18px', marginTop: '-10px' }}><span style={{ position: 'relative', color: '#8c8c8d', fontSize: '14px' }}>₹ {productsData[2]?.mrp}</span> <span className="offer-price fw-semibold">₹ {productsData[2]?.offer_price}</span></h2>
                                    <p style={{ fontSize: '14px' }} className="savings offer-percent">You save ₹{productsData[2]?.mrp - productsData[2]?.offer_price} <span className="offer-percent" style={{ fontSize: '12px' }}> ({calculateDiscountPercentage(parseInt(productsData[2]?.mrp), parseInt(productsData[2]?.offer_price))}% off)</span></p>

                                    {activeCard === productsData[2]?.id ? <div className="selected-text text-center">SELECTED</div> : <div className="recomended-badge text-center" style={{ position: 'absolute', bottom: '0px', left: '0px', borderBottomLeftRadius: '30px', borderBottomRightRadius: '10px' }}>Recommended</div>}
                                </div>
                            </div>
                            <div >
                                <div className={cardClassName(productsData[1]?.id)} onClick={() => selectProduct(productsData[1]?.id)} style={divStyle}>
                                    <h5 className='fw-semibold'>3 Month Pack</h5>
                                    <p className='light-font-weight'>{productsData[1]?.content_qty} Gummies</p>
                                    <h2 className="product-price" style={{ position: 'relative', fontSize: '18px', marginTop: '-10px' }}><span style={{ position: 'relative', color: '#8c8c8d', fontSize: '14px' }}>₹ {productsData[1]?.mrp}</span> <span className="offer-price fw-semibold">₹ {productsData[1]?.offer_price}</span></h2>
                                    <p style={{ fontSize: '14px' }} className="savings offer-percent mt-2">You save ₹{productsData[1]?.mrp - productsData[1]?.offer_price} <span className="offer-percent" style={{ fontSize: '12px' }}> ({calculateDiscountPercentage(parseInt(productsData[1]?.mrp), parseInt(productsData[1]?.offer_price))}% off)</span></p>
                                    {activeCard === productsData[1]?.id && <div className="selected-text text-center">SELECTED</div>}
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <p className="text-left mt-2 white-text px-3">Tax Inc. FREE delivery an all orders! Free COD.</p>
                    <div className='back-bg-color mt-3 p-3 d-flex align-items-center gap-5'>
                        <div style={{ width: '70%' }}>
                            <img src="assets/money-back-garuntee.png" className="img-fluid" alt="" />
                        </div>

                        <div style={{ width: '30%' }}>
                            <button className="btn add-to-cart-btn w-100" onClick={() => addToCart()}> {isAdded ? (
                                <span className="d-flex align-items-center justify-content-center gap-2">
                                    <Spinner size="sm" /> {" Loading..."}
                                </span>
                            ) : (
                                <span className='white-text'>ADD TO CART</span>
                            )}</button>
                        </div>

                    </div>

                </div>
            </div>

            <div className='d-lg-none'>
                <div className='mobile-slider-container'>
                    <Slider {...mobilePicsSettings}>
                        <div >
                            <div style={picsDiv}>

                                <img src="assets/uplife_sleepzzz.png" alt="" className='w-100' />
                            </div>
                        </div>
                        <div >
                            <div style={picsDiv}>
                                <img src="assets/bottle-side-1.png" alt="" className='w-100' />
                            </div>
                        </div>
                        <div >
                            <div style={picsDiv}>

                                <img src="assets/bottle-side-2.png" alt="" className='w-100' />
                            </div>
                        </div>
                        <div >
                            <div style={picsDiv}>

                                <img src="assets/bottle-side-3.png" alt="" className='w-100' />
                            </div>
                        </div>


                    </Slider>
                </div>

                <div>
                    <div className='p-3'>
                        <h3 className='white-text'>Up Life <br /> Sleepz<span className='fw-bold' style={{ fontSize: '26px' }}>z</span><span className='fw-bold' style={{ fontSize: '24px' }}>z</span> <small>Gummies (30 Soft Chews)&nbsp;&nbsp;</small> <img src="assets/veg-icon.png" className="veg-icon" alt="" style={{ width: '20px' }} /></h3>
                        <img src="assets/white-rating-stars.png" className="img-fluid d-inline-block mr-1 mb-2" alt="" />
                        <span className='white-text' style={{ marginLeft: '7px' }}>35+ Ratings</span>
                        <div className='horizontal-line mt-2'></div>
                        <p className="mt-2 white-text">Select Qty</p>
                    </div>
                </div>

                <div className='mobile-slider-container'>
                    <Slider {...mobileSettings}>
                        <div >
                            <div style={mobileDivStyle} className={cardClassName(productsData[0]?.id)} onClick={() => selectProduct(productsData[0]?.id)}>
                                <h5 className='fw-semibold'>1 Month Pack</h5>
                                <p className='light-font-weight'>{productsData[0]?.content_qty} Gummies</p>
                                <h2 className="product-price" style={{ position: 'relative', fontSize: '18px', marginTop: '-10px' }}><span style={{ position: 'relative', color: '#8c8c8d', fontSize: '14px' }}>₹ {productsData[0]?.mrp} </span> <span className="offer-price fw-semibold"> ₹ {productsData[0]?.offer_price}</span></h2>
                                <p style={{ fontSize: '10px' }} className="savings offer-percent">You save ₹{productsData[0]?.mrp - productsData[0]?.offer_price} <span className="offer-percent" style={{ fontSize: '10px' }}> ({calculateDiscountPercentage(parseInt(productsData[0]?.mrp), parseInt(productsData[0]?.offer_price))}% off)</span></p>
                                {activeCard === productsData[0]?.id && <div className="selected-text text-center">SELECTED</div>}
                            </div>
                        </div>
                        <div >
                            <div className={cardClassName(productsData[2]?.id)} onClick={() => selectProduct(productsData[2]?.id)} style={{ ...mobileDivStyle, position: 'relative' }}>
                                <h5 className='fw-semibold'>2 Month Pack</h5>
                                <p className='light-font-weight'>{productsData[2]?.content_qty} Gummies</p>
                                <h2 className="product-price" style={{ position: 'relative', fontSize: '18px', marginTop: '-10px' }}><span style={{ position: 'relative', color: '#8c8c8d', fontSize: '14px' }}>₹ {productsData[2]?.mrp}</span> <span className="offer-price fw-semibold">₹ {productsData[2]?.offer_price}</span></h2>
                                <p style={{ fontSize: '10px' }} className="savings offer-percent">You save ₹{productsData[2]?.mrp - productsData[2]?.offer_price} <span className="offer-percent" style={{ fontSize: '10px' }}> ({calculateDiscountPercentage(parseInt(productsData[2]?.mrp), parseInt(productsData[2]?.offer_price))}% off)</span></p>

                                {activeCard === productsData[2]?.id ? <div className="selected-text text-center">SELECTED</div> : <div className="recomended-badge text-center" style={{ position: 'absolute', bottom: '0px', left: '0px', borderBottomLeftRadius: '30px', borderBottomRightRadius: '10px' }}>Recommended</div>}
                            </div>
                        </div>
                        <div >
                            <div className={cardClassName(productsData[1]?.id)} onClick={() => selectProduct(productsData[1]?.id)} style={mobileDivStyle}>
                                <h5 className='fw-semibold'>3 Month Pack</h5>
                                <p className='light-font-weight'>{productsData[1]?.content_qty} Gummies</p>
                                <h2 className="product-price" style={{ position: 'relative', fontSize: '18px', marginTop: '-10px' }}><span style={{ position: 'relative', color: '#8c8c8d', fontSize: '14px' }}>₹ {productsData[1]?.mrp}</span> <span className="offer-price fw-semibold">₹ {productsData[1]?.offer_price}</span></h2>
                                <p style={{ fontSize: '10px' }} className="savings offer-percent mt-2">You save ₹{productsData[1]?.mrp - productsData[1]?.offer_price} <span className="offer-percent" style={{ fontSize: '10px' }}> ({calculateDiscountPercentage(parseInt(productsData[1]?.mrp), parseInt(productsData[1]?.offer_price))}% off)</span></p>
                                {activeCard === productsData[1]?.id && <div className="selected-text text-center">SELECTED</div>}
                            </div>
                        </div>
                    </Slider>
                </div>
                <div className='bg-color mt-3 p-3 d-flex align-items-center gap-5'>
                    <div style={{ width: '60%' }}>
                        <img src="assets/money-back-garuntee.png" className="img-fluid" alt="" />
                    </div>

                    <div style={{ width: '40%' }}>
                        <button className="btn add-to-cart-btn" onClick={() => addToCart()}> {isAdded ? (
                            <span className="d-flex align-items-center justify-content-center gap-2">
                                <Spinner size="sm" /> {" Loading..."}
                            </span>
                        ) : (
                            <span className='white-text w-100' style={{ fontSize: '10px' }}>ADD TO CART</span>
                        )}</button>
                    </div>

                </div>
            </div>

        </>
    )
}

export default BuyNow
