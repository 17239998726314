import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './screens/home'
import AppLayout from './Applayout'
import Address from './screens/address'
import Blogs from './screens/blogs'
import Contact from './screens/contact'
import TermsAndConditions from './screens/termsAndConditions'
import BlogDetails from './screens/blogDetails'
import BuyNow from './screens/buyNow'
import Orders from './screens/orders'
import ParentAddressComponent from './components/ParentAddressComponent'
import OrderSuccess from './components/OrderSuccess'
import OrderFailure from './components/OrderFailure'
import PrivacyPolicy from './screens/privacyPolicy'
import RefundPolicy from './screens/refundPolicy'
import ShippingPolicy from './screens/shippingPolicy'
import OrderDetails from './screens/orderDetails'

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='' element={<AppLayout />}>
        <Route path='' element={<Home />} />
        <Route path='/address' element={<ParentAddressComponent />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/blogs/:blogUriSlug' element={<BlogDetails />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/shipping-policy' element={<ShippingPolicy />} />
        <Route path='/sleepzzz-pack-of-1' element={<BuyNow />} />
        <Route path='/orders' element={<Orders />} />
        <Route path='/orders/:orderId' element={<OrderDetails />} />
        <Route path='/my-address' element={<Address />} />
        <Route path='/order-success' element={<OrderSuccess />} />
        <Route path='/payment-failure' element={<OrderFailure />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
